import {Observable, ReplaySubject, combineLatest} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';

/**
 * The number of options to show by default if the parameter is not specified.
 */
const DEFAULT_MAX_VISIBLE_OPTIONS = 2000;

/**
 * A wrapper for Mat Autocomplete that adds multiselection.
 * @Input() options: the options to choose from for autocomplete.
 * @Input() initialSelectedOptions: options that should start selected.
 * @Input() placeholderText: text for input placeholder attribute.
 * @Input() maxOptionsToShow: total number of options to display in dropdown.
 * @Input() required: if the autocomplete field should be shown as a required
 *     field with an asterisk
 * @Input() applyActionTitle: the title on the apply button.
 * @Input() allowEmpty: allow empty selection to be applied (false by default).
 * @Input() allowNewElements: allow a user to add new elements
 *     (false by default).
 * @Input() newElementName: this will be shown after "Create <<user input>>"
 * @Input() autoFocus: puts a focus on the input field automatically
 *     (true by default).
 * @Output() selectedOptionsChanged: an event that emits the chosen options as a
 *     string[] when option selection is updated.
 * @Output() apply: an event that emits when a user clicks the apply button.
 * @Output() cancel: an event that emits when a user clicks the cancel button.
 */
@Component({
  selector: 'multiselect-autocomplete',
  templateUrl: './multiselect_autocomplete.ng.html',
  styleUrls: ['./multiselect_autocomplete.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiselectAutocomplete implements OnInit, OnChanges {
  @Input() options: string[] = [];
  @Input() initialSelectedOptions: string[] = [];
  @Input() placeholderText = 'Value';
  @Input() maxOptionsToShow = DEFAULT_MAX_VISIBLE_OPTIONS;
  @Input() required = false;
  @Input() applyActionTitle = 'Apply';
  @Input() allowEmpty = false;
  @Input() allowNewElements = false;
  @Input() newElementName = '';
  @Input() autoFocus = true;
  @Input() selectAllOptionName = 'Select all';
  // Width of the autocomplete panel.
  // If not set will match the width of its host.
  @Input() panelWidth: string | number = '';

  @Output() readonly selectedOptionsChanged = new EventEmitter<string[]>();
  @Output() readonly apply = new EventEmitter<void>();
  @Output() readonly cancel = new EventEmitter<void>();

  @ViewChild('valueInput', {static: false}) valueInput!: ElementRef;
  // Reading the material autocomplete trigger directive in order to access
  // the active option.
  @ViewChild('valueInput', {static: false, read: MatAutocompleteTrigger})
  matAutocompleteTrigger!: MatAutocompleteTrigger;

  isSelectionSaved = false;
  allOptions = new ReplaySubject<string[]>(1);
  filteredOptions!: Observable<string[]>;
  selectedOptionsSet = new Set<string>();
  selectedOptionsSnapshot = '';
  valueControl = new UntypedFormControl({value: '', disabled: true});
  formGroup = new UntypedFormGroup({
    valueControl: this.valueControl,
  });
  get cleanNewValue(): string {
    return this.valueControl.value?.trim() || '';
  }

  ngOnChanges({options}: SimpleChanges) {
    if (options && options.currentValue) {
      this.clearState();
      this.inputsChanged();
      const allPossibleOptions = options.currentValue
        .filter((optionVal: string) => optionVal !== '')
        .sort();
      this.allOptions.next(allPossibleOptions);
    }
  }

  ngOnInit() {
    this.filteredOptions = combineLatest([
      this.valueControl.valueChanges.pipe(startWith('')),
      this.allOptions,
    ]).pipe(
      map(([value, options]: [string, string[]]) => {
        this.enableValueControl(!!options.length || this.allowNewElements);
        value = value || '';
        // If a value has been entered into the value input field or
        // there aren't any checked checkboxes, filter normally.
        if (value.length > 0 || this.selectedOptionsSet.size === 0) {
          return this.filterOptions(options, value.trim(), this.maxOptionsToShow, true);
        }

        // Filter a subset of options that leave out checked
        // checkboxes. Checked checkboxes will display at top of
        // autocomplete results in the case where there isn't any
        // value entered.
        const maxFilterOptionsToShow = this.maxOptionsToShow - this.selectedOptionsSet.size;
        const filteredOptions = this.filterOptions(options, value, maxFilterOptionsToShow, false);
        return [...this.selectedOptionsSet, ...filteredOptions];
      }),
    );

    if (this.autoFocus) {
      this.focusAndShowOptions();
    } else {
      this.enableValueControl(true);
    }
  }

  /**
   * New inputs set. Creates a set out of the initial selected options and
   * snapshots selected options.
   */
  inputsChanged() {
    this.selectedOptionsSet = new Set(this.initialSelectedOptions);
    const selectedOptionsString = this.buildSelectedOptionsString();
    this.selectedOptionsSnapshot = selectedOptionsString;
    this.initialSelectedOptions = [];
  }

  canAddValue(
    allowNewElements: boolean,
    filteredOptions: string[] | null,
    cleanNewValue: string,
  ): boolean {
    const canAddValue =
      allowNewElements &&
      cleanNewValue.length > 0 &&
      !(filteredOptions || []).includes(cleanNewValue);
    return canAddValue;
  }

  /**
   * Add user input as a new element if allowed
   */
  addValue(event: Event, newValue: string) {
    if (!this.allowNewElements) {
      return;
    }
    event.stopPropagation();

    this.selectedOptionsSet.add(newValue);
    this.options.push(newValue);
    this.allOptions.next([newValue]);
    this.onApply(event);
  }

  /**
   * An option has been selected via the enter key pressed or mouse click.
   * @param option: The option name (key) which maps to a checkbox.
   */
  toggleSelection(option: string) {
    const wasChecked = this.selectedOptionsSet.has(option);
    if (!wasChecked) {
      this.selectedOptionsSet.add(option);
      return;
    }

    this.selectedOptionsSet.delete(option);
  }

  /**
   * The enter key was pressed on one of the filter options
   * or in the text field.
   */
  optionEnterKeyPressed(event: Event) {
    if (!this.matAutocompleteTrigger.activeOption) {
      return;
    }

    const option = this.matAutocompleteTrigger.activeOption.value;
    if (this.options.includes(option)) {
      this.toggleSelection(option);
      return;
    }

    if (this.allowNewElements) {
      this.addValue(event, option);
    } else {
      this.selectedOptionsSet.delete(option);
    }
  }

  buildSelectedOptionsString(): string {
    return [...this.selectedOptionsSet].join(', ');
  }

  /**
   * Sets the input field to a string of selected options and emits the
   * selected options string.
   */
  autocompleteClosed() {
    const selectedOptionsString = this.buildSelectedOptionsString();
    if (selectedOptionsString !== this.selectedOptionsSnapshot) {
      this.selectedOptionsSnapshot = selectedOptionsString;
      const selectedOptions = [...this.selectedOptionsSet];
      this.selectedOptionsChanged.emit(selectedOptions);
    }
    if (this.allowNewElements) {
      // Need to manually blur the search field when no existing values match.
      // Because we need to show all selected options in the field after a user
      // has added new option.
      this.valueInput.nativeElement.blur();
    }
    if (!this.isSelectionSaved) {
      this.cancel.emit();
    }
  }

  valueInputFocused() {
    this.clearValue();
  }

  clearValue() {
    if (this.valueControl.value) {
      this.valueControl.reset();
    }
  }

  onApply(event: Event) {
    this.onActionSelected(event, true);
    this.apply.emit();
  }

  allSelected(options: string[]): boolean {
    const selectedCount = options.filter((item) => this.selectedOptionsSet.has(item)).length;
    return selectedCount === options.length;
  }

  someSelected(options: string[]): boolean {
    const selectedCount = options.filter((item) => this.selectedOptionsSet.has(item)).length;
    return selectedCount > 0 && selectedCount < options.length;
  }

  setAll(selected: boolean, options: string[]) {
    for (const option of options) {
      selected ? this.selectedOptionsSet.add(option) : this.selectedOptionsSet.delete(option);
    }
  }

  selectOnly(event: MouseEvent, option: string) {
    event.stopPropagation();
    this.selectedOptionsSet.clear();
    this.selectedOptionsSet.add(option);
  }

  private focusAndShowOptions() {
    setTimeout(() => {
      this.valueInput.nativeElement.focus();
      if (!this.matAutocompleteTrigger.panelOpen) {
        this.matAutocompleteTrigger.openPanel();
      }
    }, 0);
  }

  private onActionSelected(event: Event, isSaved: boolean) {
    this.isSelectionSaved = isSaved;
    event.preventDefault();
    event.stopImmediatePropagation();
    this.matAutocompleteTrigger.closePanel();
  }

  private enableValueControl(enable: boolean) {
    if (enable && this.valueControl.disabled) {
      this.valueControl.enable({emitEvent: false});
      return;
    }

    if (!enable && this.valueControl.enabled) {
      this.valueControl.disable({emitEvent: false});
    }
  }

  private filterOptions(
    options: string[],
    filterValue: string,
    maxFilterOptionsToShow: number,
    includeCheckedCheckboxes: boolean,
  ): string[] {
    filterValue = filterValue.toLowerCase();

    const optionsToShow = [];
    for (let option of options) {
      option = String(option);
      if (!includeCheckedCheckboxes && this.selectedOptionsSet.has(option)) {
        continue;
      }

      if (option.toLowerCase().includes(filterValue)) {
        optionsToShow.push(option);
        if (optionsToShow.length >= maxFilterOptionsToShow) {
          break;
        }
      }
    }

    return optionsToShow;
  }

  /**
   * Clears the state of the component. This occurs when the input options
   * change.
   */
  private clearState() {
    this.selectedOptionsSet.clear();
    this.valueControl.reset();
  }
}
