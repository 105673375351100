// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/layerservice.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Layer, LayerStyle } from "./layer_pb.js";
import { BoundingBox } from "./geometry_pb.js";
import { Feature, FeatureIdMap, Property } from "./feature_pb.js";
import { ImageAnnotationLabel } from "./image_annotation_pb.js";
import { ImageAssociationStatus, LifecycleStage } from "./common_pb.js";
import { LatLng } from "../../../../google/type/latlng_pb.js";
import { Tag } from "./tag_pb.js";

/**
 * @generated from message tapestry.gridaware.api.v1.GetLayersRequest
 */
export const GetLayersRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.GetLayersRequest", []);

/**
 * @generated from message tapestry.gridaware.api.v1.GetLayersResponse
 */
export const GetLayersResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.GetLayersResponse", () => [{
  no: 1,
  name: "layers",
  kind: "message",
  T: Layer,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetPropertyKeysRequest
 */
export const GetPropertyKeysRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.GetPropertyKeysRequest", () => [{
  no: 1,
  name: "layer_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "include_inactive",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetPropertyKeysResponse
 */
export const GetPropertyKeysResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.GetPropertyKeysResponse", () => [{
  no: 2,
  name: "keys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetAutocompleteResultsRequest
 */
export const GetAutocompleteResultsRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.GetAutocompleteResultsRequest", () => [{
  no: 1,
  name: "layer_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "substring",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "max_results",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 5,
  name: "include_inactive",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetAutocompleteResultsResponse
 */
export const GetAutocompleteResultsResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.GetAutocompleteResultsResponse", () => [{
  no: 1,
  name: "values",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.QueryFeaturesRequest
 */
export const QueryFeaturesRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.QueryFeaturesRequest", () => [{
  no: 1,
  name: "layer_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "query",
  kind: "message",
  T: LayerQuery
}, {
  no: 3,
  name: "max_results",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "include_inactive",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 5,
  name: "pagination_options",
  kind: "message",
  T: QueryFeaturesPaginationOptions
}]);

/**
 * A list of parameters that must all be met in order for a layer feature to be
 * included.
 *
 * @generated from message tapestry.gridaware.api.v1.LayerQuery
 */
export const LayerQuery = proto3.makeMessageType("tapestry.gridaware.api.v1.LayerQuery", () => [{
  no: 1,
  name: "bounds",
  kind: "message",
  T: BoundingBox
}, {
  no: 2,
  name: "properties",
  kind: "message",
  T: Property,
  repeated: true
}, {
  no: 3,
  name: "tag_names",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 4,
  name: "image_annotation_filter",
  kind: "message",
  T: ImageAnnotationFilter
}, {
  no: 5,
  name: "image_association_filter",
  kind: "message",
  T: ImageAssociationFilter
}]);

/**
 * Parameters for filtering features according to annotations on associated
 * images.
 *
 * @generated from message tapestry.gridaware.api.v1.ImageAnnotationFilter
 */
export const ImageAnnotationFilter = proto3.makeMessageType("tapestry.gridaware.api.v1.ImageAnnotationFilter", () => [{
  no: 1,
  name: "feature_has_images_with_annotations",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */,
  oneof: "label_based_filter"
}, {
  no: 2,
  name: "combined_image_labels",
  kind: "message",
  T: ImageAnnotationFilter_LabelFilter,
  oneof: "label_based_filter"
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.ImageAnnotationFilter.LabelFilter
 */
export const ImageAnnotationFilter_LabelFilter = proto3.makeMessageType("tapestry.gridaware.api.v1.ImageAnnotationFilter.LabelFilter", () => [{
  no: 1,
  name: "includes_any",
  kind: "enum",
  T: proto3.getEnumType(ImageAnnotationLabel),
  repeated: true
}, {
  no: 2,
  name: "excludes_all",
  kind: "enum",
  T: proto3.getEnumType(ImageAnnotationLabel),
  repeated: true
}], {
  localName: "ImageAnnotationFilter_LabelFilter"
});

/**
 * Parameters for filtering assets according to the associated images.
 *
 * @generated from message tapestry.gridaware.api.v1.ImageAssociationFilter
 */
export const ImageAssociationFilter = proto3.makeMessageType("tapestry.gridaware.api.v1.ImageAssociationFilter", () => [{
  no: 1,
  name: "source_based_filter",
  kind: "enum",
  T: proto3.getEnumType(ImageAssociationStatus),
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.QueryFeaturesPaginationOptions
 */
export const QueryFeaturesPaginationOptions = proto3.makeMessageType("tapestry.gridaware.api.v1.QueryFeaturesPaginationOptions", () => [{
  no: 1,
  name: "page_token",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "page_size",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.QueryFeaturesResponse
 */
export const QueryFeaturesResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.QueryFeaturesResponse", () => [{
  no: 1,
  name: "features",
  kind: "message",
  T: Feature,
  repeated: true
}, {
  no: 2,
  name: "next_page_token",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetFeaturesAutocompleteResultsRequest
 */
export const GetFeaturesAutocompleteResultsRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.GetFeaturesAutocompleteResultsRequest", () => [{
  no: 1,
  name: "layer_ids",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 2,
  name: "substring",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "max_results",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetFeaturesAutocompleteResultsResponse
 */
export const GetFeaturesAutocompleteResultsResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.GetFeaturesAutocompleteResultsResponse", () => [{
  no: 1,
  name: "features",
  kind: "message",
  T: Feature,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetQueryFeaturesCountRequest
 */
export const GetQueryFeaturesCountRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.GetQueryFeaturesCountRequest", () => [{
  no: 1,
  name: "layer_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "query",
  kind: "message",
  T: LayerCountQuery
}]);

/**
 * A list of parameters that must all be met in order for a layer feature to be
 * included into the resulting count.
 *
 * @generated from message tapestry.gridaware.api.v1.LayerCountQuery
 */
export const LayerCountQuery = proto3.makeMessageType("tapestry.gridaware.api.v1.LayerCountQuery", () => [{
  no: 1,
  name: "properties",
  kind: "message",
  T: Property,
  repeated: true
}, {
  no: 3,
  name: "tag_names",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 4,
  name: "image_annotation_filter",
  kind: "message",
  T: ImageAnnotationFilter
}, {
  no: 5,
  name: "image_association_filter",
  kind: "message",
  T: ImageAssociationFilter
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetQueryFeaturesCountResponse
 */
export const GetQueryFeaturesCountResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.GetQueryFeaturesCountResponse", () => [{
  no: 1,
  name: "total_count",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 2,
  name: "active_count",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 3,
  name: "inactive_count",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetFeaturesRequest
 */
export const GetFeaturesRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.GetFeaturesRequest", () => [{
  no: 1,
  name: "layer_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "feature_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 3,
  name: "include_images_of_asset",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetFeaturesResponse
 */
export const GetFeaturesResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.GetFeaturesResponse", () => [{
  no: 1,
  name: "features",
  kind: "message",
  T: Feature,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetNearbyFeaturesRequest
 */
export const GetNearbyFeaturesRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.GetNearbyFeaturesRequest", () => [{
  no: 1,
  name: "layer_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "location",
  kind: "message",
  T: LatLng
}, {
  no: 3,
  name: "max_results",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "bearing",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */,
  opt: true
}, {
  no: 5,
  name: "max_meters_from_location",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}, {
  no: 6,
  name: "properties",
  kind: "message",
  T: Property,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetNearbyFeaturesResponse
 */
export const GetNearbyFeaturesResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.GetNearbyFeaturesResponse", () => [{
  no: 1,
  name: "nearby_features",
  kind: "message",
  T: GetNearbyFeaturesResponse_NearbyFeature,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetNearbyFeaturesResponse.NearbyFeature
 */
export const GetNearbyFeaturesResponse_NearbyFeature = proto3.makeMessageType("tapestry.gridaware.api.v1.GetNearbyFeaturesResponse.NearbyFeature", () => [{
  no: 1,
  name: "feature",
  kind: "message",
  T: Feature
}, {
  no: 2,
  name: "distance_meters",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}, {
  no: 3,
  name: "bearing",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}], {
  localName: "GetNearbyFeaturesResponse_NearbyFeature"
});

/**
 * TODO(b/147636120): Feature (Asset, Image, Geojson) relations should be
 * saved on ingestion. This proto can be removed then.
 *
 * @generated from message tapestry.gridaware.api.v1.GetFeatureIdsRequest
 */
export const GetFeatureIdsRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.GetFeatureIdsRequest", () => [{
  no: 1,
  name: "external_ids",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);

/**
 * TODO(b/147636120): Feature (Asset, Image, Geojson) relations should be
 * saved on ingestion. This proto can be removed then.
 *
 * @generated from message tapestry.gridaware.api.v1.GetFeatureIdsResponse
 */
export const GetFeatureIdsResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.GetFeatureIdsResponse", () => [{
  no: 1,
  name: "feature_ids",
  kind: "message",
  T: FeatureIdMap,
  repeated: true
}]);

/**
 * Next ID: 10
 *
 * @generated from message tapestry.gridaware.api.v1.AddDefectRequest
 */
export const AddDefectRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.AddDefectRequest", () => [{
  no: 7,
  name: "external_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 1,
  name: "location",
  kind: "message",
  T: LatLng
}, {
  no: 2,
  name: "properties",
  kind: "message",
  T: Property,
  repeated: true
}, {
  no: 3,
  name: "asset_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "tags",
  kind: "message",
  T: Tag,
  repeated: true
}, {
  no: 5,
  name: "image_ids",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 6,
  name: "lifecycle_stage",
  kind: "enum",
  T: proto3.getEnumType(LifecycleStage)
}, {
  no: 8,
  name: "inspection_time",
  kind: "message",
  T: Timestamp
}, {
  no: 9,
  name: "new_feature_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.AddDefectResponse
 */
export const AddDefectResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.AddDefectResponse", () => [{
  no: 1,
  name: "defect",
  kind: "message",
  T: Feature
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.UpdateDefectRequest
 */
export const UpdateDefectRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.UpdateDefectRequest", () => [{
  no: 1,
  name: "defect",
  kind: "message",
  T: Feature
}, {
  no: 2,
  name: "asset_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "image_ids",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.UpdateDefectResponse
 */
export const UpdateDefectResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.UpdateDefectResponse", () => [{
  no: 1,
  name: "defect",
  kind: "message",
  T: Feature
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.UpdateFeatureTagsRequest
 */
export const UpdateFeatureTagsRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.UpdateFeatureTagsRequest", () => [{
  no: 1,
  name: "feature_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "tags",
  kind: "message",
  T: Tag,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.UpdateFeatureTagsResponse
 */
export const UpdateFeatureTagsResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.UpdateFeatureTagsResponse", []);

/**
 * A request to update or insert properties of a feature.
 * Deleting properties is not supported and can be added later if needed.
 *
 * @generated from message tapestry.gridaware.api.v1.UpdateFeaturePropertiesRequest
 */
export const UpdateFeaturePropertiesRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.UpdateFeaturePropertiesRequest", () => [{
  no: 1,
  name: "feature_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "update_or_insert_properties",
  kind: "message",
  T: Property,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.UpdateFeaturePropertiesResponse
 */
export const UpdateFeaturePropertiesResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.UpdateFeaturePropertiesResponse", []);

/**
 * @generated from message tapestry.gridaware.api.v1.UpdateLayerConfigRequest
 */
export const UpdateLayerConfigRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.UpdateLayerConfigRequest", () => [{
  no: 1,
  name: "layer_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "layer_style",
  kind: "message",
  T: LayerStyle
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.UpdateLayerConfigResponse
 */
export const UpdateLayerConfigResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.UpdateLayerConfigResponse", () => [{
  no: 1,
  name: "layer_style",
  kind: "message",
  T: LayerStyle
}]);