<div class="container" [hidden]="!featureInfo.feature">
  <breadcrumb-navigation></breadcrumb-navigation>

  <div class="hero-photo" [hidden]="!featureInfo.isPoint">
    <div class="satellite-map" [hidden]="loading || images.length" #heroSatellite></div>
    @if (loading) {
      <mat-progress-spinner mode="indeterminate" color="primary" diameter="56" class="spinner">
      </mat-progress-spinner>
    } @else if (images.length) {
      <lightbox
        [image]="images[currentImageIndex]"
        [images]="images"
        [imageUrlOptions]="thumbnailUrlOptions"
        [isThumbnail]="true"
        [backgroundSize]="'contain'"
        [thumbnailMetadata]="thumbnailMetadata"
        (previousPressed)="selectImage('prev')"
        (nextPressed)="selectImage('next')"
        (imagePressed)="openLightbox()"
        (onEdit)="editImage()">
      </lightbox>
    } @else if (isOffline) {
      <div class="hero-photo offline-container">
        <mat-icon class="offline-icon">cloud_off</mat-icon>
      </div>
    } @else if (showSolarInsights) {
      <div class="panel">
        <div class="panel-header panel-header-border-bottom">Solar Analysis by Feeder</div>
        <solar-insights
          [feature]="featureInfo.feature"
          [featureProperties]="featureInfo.properties">
        </solar-insights>
      </div>
    }
  </div>
  @if (!showSolarInsights) {
    <div class="panel">
      @if (layerInfo.name) {
        <div class="panel-header first-header">{{ layerInfo.name }}</div>
      }
      <div class="feature-name">
        @if (featureInfo.name) {
          <div class="title">
            <div class="title-name">{{ featureInfo.name }}</div>
            <button
              mat-icon-button
              class="icon-button copy"
              color="primary"
              (click)="copyFeatureName()"
              aria-label="Copy ID"
              i18n-aria-label
              matTooltip="Copy ID">
              <mat-icon class="feature-details-icon">content_copy</mat-icon>
            </button>
          </div>
        }
        @if (editsEnabled) {
          <button
            mat-icon-button
            class="icon-button edit-defect"
            color="primary"
            (click)="editDefect()"
            aria-label="Edit"
            i18n-aria-label
            matTooltip="Edit">
            <mat-icon class="feature-details-icon">create_black</mat-icon>
          </button>
        }
      </div>
      @if (featureInfo.lastUpdatedAt) {
        <div class="last-updated" [ngClass]="{'feature-inactive': featureInfo.isInactive}">
          {{ featureInfo.isInactive ? 'Closed' : 'Last updated' }}
          {{ featureInfo.lastUpdatedAt | relativeDateTime }}
        </div>
      }
      @if (featureInfo.address | async; as address) {
        <div>
          <mat-icon color="primary">location_on</mat-icon>
          <a
            class="address"
            href="https://www.google.com/maps/search/?api=1&query={{ address }}"
            target="_blank">
            {{ address }}
          </a>
        </div>
      }
      <div class="street-view-photo-container" [hidden]="!featureInfo.isPoint">
        @if (!isOffline) {
          <div class="street-view" #streetViewContainer [hidden]="!featureInfo.isPoint"></div>
          @if (images.length) {
            <div class="thumbnail" (click)="openLightbox()" role="button">
              <div class="icon-container">
                <mat-icon class="icon-input">collections</mat-icon>
                <div class="icon-text">More photos</div>
              </div>
              <image-thumbnail [image]="images[0]" [imageUrlOptions]="thumbnailUrlOptions">
              </image-thumbnail>
            </div>
          }
        } @else {
          <div class="street-view offline-container">
            <mat-icon class="offline-icon">cloud_off</mat-icon>
          </div>
        }
      </div>
      @if (uploadsEnabled) {
        <button
          class="feature-details-button upload"
          color="primary"
          (click)="goToImageUpload()"
          mat-stroked-button
          aria-label="Add photos"
          i18n-aria-label>
          <mat-icon class="icon">add_a_photo</mat-icon>
          Add photos
        </button>
      }
    </div>
  }
  @if (isInspectionStatusVisible) {
    <div class="panel">
      <div class="panel-header">Inspection status</div>
      <inspection-status [featureId]="featureInfo.id" [properties]="featureInfo.properties">
      </inspection-status>
    </div>
  }
  @if (!isOffline && !showSolarInsights && featureUpdates | async) {
    <div class="panel">
      <div class="panel-header">Activity timeline</div>
      <timeline [feature]="featureUpdates"></timeline>
    </div>
    @for (
      relatedFeatureDisplay of featureInfo.relatedFeatureDisplays;
      track relatedFeatureDisplay
    ) {
      <div class="panel">
        <div class="panel-header">{{ relatedFeatureDisplay.layerName }}</div>
        @for (related of relatedFeatureDisplay.relatedFeatures; track related) {
          <div class="related-features">
            <span class="related-feature-title">{{ related.displayName }}</span>
            <button
              mat-icon-button
              class="icon-button related-nav-button"
              color="primary"
              (click)="goToRelatedFeature(related)"
              aria-label="Open"
              i18n-aria-label="Open"
              [matTooltip]="'Open'"
              [matTooltipPosition]="'above'">
              <mat-icon class="feature-details-icon">open_in_new</mat-icon>
            </button>
          </div>
        }
      </div>
    }
    @if (tagsEnabled && !showSolarInsights) {
      <div class="panel">
        <div class="panel-header">Tags</div>
        <tags
          [tags]="featureInfo.tags"
          [layerId]="layerInfo.id"
          (tagsUpdated)="doUpdateTags($event)">
        </tags>
      </div>
    }
  }
  <div class="panel last-panel">
    <div class="panel-header">Details</div>
    <metadata
      [layerId]="layerInfo.id"
      [properties]="featureInfo.properties"
      [selectedFeature]="featureInfo.name">
    </metadata>
  </div>
</div>
