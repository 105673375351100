import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';

import {MultiselectAutocompleteModule} from '../../multiselect_autocomplete/multiselect_autocomplete_module';
import {ObservationInput} from './observation_input';

@NgModule({
  declarations: [ObservationInput],
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, MultiselectAutocompleteModule],
  exports: [ObservationInput],
})
export class ObservationInputModule {}
