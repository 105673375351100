import {PromiseClient} from '@connectrpc/connect';
import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';
// import {RelatedFeaturesGroup} from 'google3/googlex/refinery/viaduct/proto/gridaware/common.proto';
// import {Image} from 'google3/googlex/refinery/viaduct/proto/gridaware/image.proto';
// import {ImageAnnotationLabel} from 'google3/googlex/refinery/viaduct/proto/gridaware/image_annotation.proto';
// import {
//   FeatureIdMap,
//   Property,
// } from 'google3/googlex/refinery/viaduct/proto/gridaware/layer.proto';
// import {Tag} from 'google3/googlex/refinery/viaduct/proto/gridaware/tag.proto';
import {Observable, ReplaySubject, defer, of} from 'rxjs';
import {map, mergeMap, take, tap} from 'rxjs/operators';

import {Injectable} from '@angular/core';

import {
  ImageAssociationStatus,
  LifecycleStage,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/common_pb';
import {
  Feature,
  FeatureIdMap,
  Property,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {BoundingBox} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/geometry_pb';
import {ImageAnnotationLabel} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_annotation_pb';
import {LayerService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layerservice_connect';
import {
  AddDefectResponse,
  GetFeatureIdsRequest,
  GetFeatureIdsResponse,
  GetFeaturesAutocompleteResultsRequest,
  GetFeaturesAutocompleteResultsResponse,
  GetFeaturesRequest,
  GetFeaturesResponse,
  GetNearbyFeaturesResponse,
  GetNearbyFeaturesResponse_NearbyFeature,
  GetQueryFeaturesCountRequest,
  GetQueryFeaturesCountResponse,
  ImageAnnotationFilter,
  ImageAnnotationFilter_LabelFilter,
  ImageAssociationFilter,
  LayerCountQuery,
  LayerQuery,
  QueryFeaturesPaginationOptions,
  QueryFeaturesRequest,
  QueryFeaturesResponse,
  UpdateDefectResponse,
  UpdateFeaturePropertiesRequest,
  UpdateFeatureTagsRequest,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layerservice_pb';
import {Tag} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/tag_pb';

import {LABELS} from '../constants/annotations';
import {FilterField} from '../constants/filters';
import {AnnotationFilterOption, LabelInfo} from '../typings/annotations';
import {ASSET_IMAGE_SOURCE_MAP} from '../typings/asset_filter';
import {FilterMap} from '../typings/filter';
import {convertToProperties} from '../utils/feature';
import {ApiService} from './api_service';

/**
 *  Request payload for the QueryFeatures.
 */
export interface QueryFeaturesRequestPayload {
  layerId: string;
  includeInactiveResults?: boolean;
  filters?: FilterMap;
  maxResults?: number;
  pagination?: QueryFeaturesPagination;
}

/**
 * Pagination interface for the QueryFeatures.
 * pageToken is generated by the server side, for the first page should be an
 * empty string.
 */
export interface QueryFeaturesPagination {
  pageToken: string;
  pageSize: number;
}

/**
 * The number of active and inactive features for a given query.
 */
export interface QueryFeaturesCount {
  active: number;
  inactive: number;
}

const QUERY_MAX_RESULTS = 2000;

/**
 * Request parameters for GetNearbyFeatures.
 */
export interface GetNearbyFeaturesRequestPayload {
  layerId: string;
  location: LatLng;
  maxResults?: number;
  bearing?: number;
  maxMetersFromLocation?: number;
  properties?: Property[];
}

/**
 * Pagination interface for the QueryFeatures.
 * pageToken is generated by the server side, for the first page should be an
 * empty string.
 */
export interface QueryFeaturesPagination {
  pageToken: string;
  pageSize: number;
}

// const QUERY_MAX_RESULTS = 2000;
const QUERY_BY_STRING_MAX_RESULTS = 5;
// const OFFLINE_ASSET_IDS_MAX_RESULTS = 5e6;

/**
 * Service for querying features.
 */
@Injectable({providedIn: 'root'})
export class FeaturesService {
  private readonly client: PromiseClient<typeof LayerService>;
  private readonly featureById = new Map<string, ReplaySubject<Feature>>();
  private readonly featureDeletionUpdates = new ReplaySubject<Feature | null>(1);

  constructor(private readonly apiService: ApiService) {
    this.client = this.apiService.createLayerServiceBEClient();
  }

  /**
   * Fetches layer features that meet certain parameters in bounds.
   */
  queryFeaturesInBounds(
    layerId: string,
    bounds: google.maps.LatLngBounds,
    filters?: FilterMap,
    includeInactiveResults?: boolean,
  ): Observable<Feature[]> {
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();
    const layerQuery = this.createLayerQuery(filters);
    layerQuery.bounds = new BoundingBox({
      lo: new LatLng({latitude: southWest.lat(), longitude: southWest.lng()}),
      hi: new LatLng({latitude: northEast.lat(), longitude: northEast.lng()}),
    });

    const request = new QueryFeaturesRequest({
      layerId: layerId,
      query: layerQuery,
      includeInactive: includeInactiveResults || false,
      maxResults: QUERY_MAX_RESULTS,
    });

    return defer(() =>
      this.apiService
        .withCallOptions((callOptions) => this.client.queryFeatures(request, callOptions))
        .then(
          (response: QueryFeaturesResponse) => {
            return response.features;
          },
          (error: Error) => {
            throw new Error(
              `Could not query features in bounds for layer ${layerId}: ${error.message}`,
            );
          },
        ),
    );
  }

  /**
   * Fetches layer features that meet certain parameters in bounds.
   */
  queryFeaturesInBoundsWithPagination(
    layerId: string,
    bounds: google.maps.LatLngBounds,
    pagination: QueryFeaturesPagination,
    filters?: FilterMap,
    includeInactiveResults?: boolean,
  ): Observable<QueryFeaturesResponse> {
    const layerQuery = this.createLayerQueryWithBounds(bounds, filters);
    const paginationOptions = new QueryFeaturesPaginationOptions({
      pageSize: pagination.pageSize,
    });
    if (pagination.pageToken) {
      paginationOptions.pageToken = pagination.pageToken;
    }

    const request = new QueryFeaturesRequest({
      layerId,
      query: layerQuery,
      includeInactive: includeInactiveResults || false,
      paginationOptions,
      maxResults: QUERY_MAX_RESULTS,
    });

    return defer(() =>
      this.apiService
        .withCallOptions((callOptions) => this.client.queryFeatures(request, callOptions))
        .then(
          (response: QueryFeaturesResponse) => {
            return response;
          },
          (error: Error) => {
            throw new Error(
              `Could not query features in bounds for layer ${layerId}: ${error.message}`,
            );
          },
        ),
    );
  }

  /**
   * Fetches layer features that meet certain parameters.
   */
  queryFeatures({
    layerId,
    filters,
    maxResults,
    includeInactiveResults,
    pagination,
  }: QueryFeaturesRequestPayload): Observable<QueryFeaturesResponse> {
    const request = new QueryFeaturesRequest({
      layerId: layerId,
      includeInactive: includeInactiveResults || false,
      maxResults: maxResults,
    });

    if (pagination) {
      const pageSize = pagination.pageSize;
      const options = new QueryFeaturesPaginationOptions({
        pageToken: pagination.pageToken,
        pageSize: pageSize,
      });
      request.paginationOptions = options;
    }

    if (filters) {
      const query = this.createLayerQuery(filters);
      request.query = query;
    }

    return defer(() =>
      this.apiService
        .withCallOptions<QueryFeaturesResponse>((callOptions) =>
          this.client.queryFeatures(request, callOptions),
        )
        .then(
          (response: QueryFeaturesResponse) => response,
          (error: Error) => {
            throw new Error(`Could not query features for layer ${layerId}: ${error.message}`);
          },
        ),
    );
  }

  /**
   * Fetches features match provided query string.
   */
  getFeaturesAutocompleteResults(
    layerId: string,
    query: string,
    queryMaxResults?: number,
  ): Observable<Feature[]> {
    const request = new GetFeaturesAutocompleteResultsRequest({
      layerIds: [layerId],
      substring: query,
      maxResults: queryMaxResults || QUERY_BY_STRING_MAX_RESULTS,
    });

    return defer(() =>
      this.apiService
        .withCallOptions((callOptions) =>
          this.client.getFeaturesAutocompleteResults(request, callOptions),
        )
        .then(
          (response: GetFeaturesAutocompleteResultsResponse) => {
            return response.features;
          },
          (error: Error) => {
            throw new Error(
              `Could not query features by query string for layer ${layerId}: ${error.message}`,
            );
          },
        ),
    );
  }

  /**
   * Get the number of features that match a filtering criteria.
   */
  getQueryFeaturesCount(layerId: string, filters: FilterMap): Observable<QueryFeaturesCount> {
    const query = this.createLayerCountQuery(filters);
    const request = new GetQueryFeaturesCountRequest({
      layerId: layerId,
      query: query,
    });
    return defer(() =>
      this.apiService
        .withCallOptions((callOptions) => this.client.getQueryFeaturesCount(request, callOptions))
        .then(
          (response: GetQueryFeaturesCountResponse) => {
            return {
              active: Number(response.activeCount),
              inactive: Number(response.activeCount),
            };
          },
          (error: Error) => {
            throw new Error(`Could not get feature count for layer ${layerId}: ${error.message}`);
          },
        ),
    );
  }

  /**
   * Fetches a layer feature.
   */
  getFeature(layerId: string, featureId: string, forceFetch: boolean): Observable<Feature | null> {
    if (!forceFetch && this.featureById.has(featureId)) {
      return this.featureById.get(featureId)!.asObservable();
    }
    const featureSubject = this.getCachedFeature(featureId);
    const request = new GetFeaturesRequest({
      layerId: layerId,
      featureId: [featureId],
    });
    this.apiService
      .withCallOptions((callOptions) => this.client.getFeatures(request, callOptions))
      .then(
        (response: GetFeaturesResponse) => {
          featureSubject.next(response.features[0] || null);
        },
        (error: Error) => {
          throw new Error(
            `Could not get feature ${featureId} for layer ${layerId}: ${error.message}`,
          );
        },
      );
    return featureSubject.asObservable();
  }

  /**
   * Fetches a set of features of a layer using feature IDs.
   */
  getFeaturesByIds(layerId: string, featureIds: string[]): Observable<Feature[]> {
    const request = new GetFeaturesRequest({
      layerId: layerId,
      featureId: featureIds,
    });
    return defer(() =>
      this.apiService
        .withCallOptions((callOptions) => this.client.getFeatures(request, callOptions))
        .then(
          (response: GetFeaturesResponse) => response.features,
          (error: Error) => {
            throw new Error(`Could not get features for layer ${layerId}: ${error.message}`);
          },
        ),
    );
  }

  /**
   * Fetches an array of FeatureIdMap which maps external ids to internal ids.
   * Features in this case refer to Asset, Image, or Native (Geojson).
   */
  getFeatureIds(externalIds: string[]): Observable<FeatureIdMap[]> {
    const request = new GetFeatureIdsRequest({externalIds: externalIds});

    return defer(() =>
      this.apiService
        .withCallOptions((callOptions) => this.client.getFeatureIds(request, callOptions))
        .then(
          (response: GetFeatureIdsResponse) => {
            return response.featureIds;
          },
          (error: Error) => {
            throw new Error(`Could not get external ids ${externalIds}: ${error.message}`);
          },
        ),
    );
  }

  //   /**
  //    * Fetches external IDs of active features that belong to the layer
  //    * with specified layerID.
  //    */
  //   getExternalIds(layerId: string): Observable<string[]> {
  //     const request = new GetFeatureExternalIdsRequest()
  //       .setLayerId(layerId)
  //       .setMaxResults(OFFLINE_ASSET_IDS_MAX_RESULTS);

  //     return defer(() =>
  //       this.apiService
  //         .withMetadata((metadata) =>
  //           this.client.getFeatureExternalIds(request, metadata),
  //         )
  //         .then(
  //           (response: GetFeatureExternalIdsResponse) => {
  //             return response.getExternalIdsList();
  //           },
  //           (error: Error) => {
  //             throw new Error(
  //               `Failed to fetch external ids for layer ${layerId}: ${error.message}`,
  //             );
  //           },
  //         ),
  //     );
  //   }

  addDefect(
    location: LatLng,
    properties: Property[],
    assetId: string,
    tags: Tag[],
    imageIds?: string[],
  ): Observable<Feature | null> {
    return defer(() =>
      this.apiService
        .withCallOptions((callOptions) =>
          this.client.addDefect({assetId, imageIds, location, properties, tags}, callOptions),
        )
        .then(
          (response: AddDefectResponse) => {
            return response.defect || null;
          },
          (error: Error) => {
            throw new Error(`Could not add defect: ${error.message}`);
          },
        ),
    );
  }

  /**
   * If the asset string is '', then the defect -> asset relation will be
   * removed. If imageIds is empty, then the defect will be deleted.
   */
  updateDefect(defect: Feature, assetId: string, imageIds: string[]): Observable<Feature | null> {
    return defer(() =>
      this.apiService
        .withCallOptions((callOptions) =>
          this.client.updateDefect({defect, assetId, imageIds}, callOptions),
        )
        .then(
          (response: UpdateDefectResponse) => {
            const updatedDefect = response.defect || null;
            this.cacheFeature(updatedDefect);
            if (updatedDefect?.lifecycleStage === LifecycleStage.STATE_UNSPECIFIED) {
              this.featureDeletionUpdates.next(updatedDefect);
              this.removeFeatureFromCache(updatedDefect);
            }
            return updatedDefect;
          },
          (error: Error) => {
            throw new Error(`Could not update defect: ${error.message}`);
          },
        ),
    );
  }

  /**
   * Updates on the feature deletion triggered by the user.
   */
  onFeatureDeleted(): Observable<Feature | null> {
    return this.featureDeletionUpdates.asObservable();
  }

  updateTags(layerId: string, featureId: string, tags: Tag[]): Observable<void> {
    const request = new UpdateFeatureTagsRequest({
      featureId: featureId,
      tags: tags,
    });
    return defer(() =>
      this.apiService
        .withCallOptions((callOptions) => this.client.updateFeatureTags(request, callOptions))
        .then(
          () => {
            return;
          },
          (error: Error) => {
            throw new Error(`Could not update tags: ${error.message}`);
          },
        ),
    ).pipe(mergeMap(() => this.updateCachedEntryTags(layerId, featureId, tags)));
  }

  /**
   * Updates the `properties` for a specific `featureId`.
   */
  updateProperties(featureId: string, properties: Property[]): Observable<void> {
    const request = new UpdateFeaturePropertiesRequest({
      featureId: featureId,
      updateOrInsertProperties: properties,
    });
    return defer(() =>
      this.apiService
        .withCallOptions((callOptions) => this.client.updateFeatureProperties(request, callOptions))
        .then(
          () => {
            return;
          },
          (error: Error) => {
            throw new Error(`Could not update properties: ${error.message}`);
          },
        ),
    ).pipe(mergeMap(() => this.updateCachedEntryProperties(featureId, properties)));
  }

  checkGeometryExists(feature: Feature): boolean {
    const geometry = feature.geometry;
    if (!geometry) {
      return false;
    }
    return !!geometry.geometry.value;
  }

  /**
   * Returns features in the given layer near the given location.
   */
  getNearbyFeatures(
    payload: GetNearbyFeaturesRequestPayload,
  ): Observable<GetNearbyFeaturesResponse_NearbyFeature[]> {
    return defer(() =>
      this.apiService
        .withCallOptions((callOptions) =>
          this.client.getNearbyFeatures(
            {
              layerId: payload.layerId,
              location: payload.location,
              maxResults: payload.maxResults,
              bearing: payload.bearing,
              maxMetersFromLocation: payload.maxMetersFromLocation,
              properties: payload.properties,
            },
            callOptions,
          ),
        )
        .then(
          (response: GetNearbyFeaturesResponse) => response.nearbyFeatures,
          (error: Error) => {
            throw new Error(`Could not get nearby features: ${error.message}`);
          },
        ),
    );
  }

  /**
   * If the feature with given ID has been previously cached, updates its tags.
   * Supposed to be called whenever the UpdateFeatureTags operation is
   * successfully performed. This is needed since for saving the bandwidth the
   * UpdateFeatureTags response doesn't return the updated entry.
   */
  private updateCachedEntryTags(layerId: string, featureId: string, tags: Tag[]): Observable<void> {
    if (!this.featureById.has(featureId)) {
      this.getFeature(layerId, featureId, true);
    }
    return this.featureById.get(featureId)!.pipe(
      take(1),
      tap((feature: Feature | null) => {
        if (feature) {
          feature.tags = tags;
          this.cacheFeature(feature);
        }
      }),
      map((): void => {
        return;
      }),
    );
  }

  /**
   * If the feature with given ID has been previously cached, updates its
   * properties. Supposed to be called whenever the UpdateFeatureProperties
   * operation is successfully performed. This is needed since for saving the
   * bandwidth the UpdateFeatureProperties response doesn't return the updated
   * entry.
   */
  private updateCachedEntryProperties(featureId: string, properties: Property[]): Observable<void> {
    const targetFeature: Observable<Feature | null> = this.featureById.has(featureId)
      ? this.featureById.get(featureId)!
      : of(null);
    return targetFeature.pipe(
      take(1),
      tap((feature: Feature | null) => {
        if (feature) {
          for (const prop of properties) {
            this.setPropertyOnFeature(feature, prop);
          }
          this.cacheFeature(feature);
        }
      }),
      map((): void => {
        return;
      }),
    );
  }

  private setPropertyOnFeature(feature: Feature, property: Property) {
    for (const prop of feature.properties) {
      if (prop.key === property.key) {
        prop.propertyValue = property.propertyValue;
        return;
      }
    }
    feature.properties = [...feature.properties, property];
  }

  private createLayerQuery(filters?: FilterMap): LayerQuery {
    const [properties, tagNames, annotationFilter, imageAssociationFilter] =
      this.arrangeFilters(filters);
    return new LayerQuery({
      properties: properties,
      tagNames: tagNames,
      imageAnnotationFilter: annotationFilter || {},
      imageAssociationFilter: imageAssociationFilter || {},
    });
  }

  private createLayerQueryWithBounds(
    bounds: google.maps.LatLngBounds,
    filters?: FilterMap,
  ): LayerQuery {
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();

    const layerQuery = this.createLayerQuery(filters);
    layerQuery.bounds = new BoundingBox({
      lo: new LatLng({
        latitude: southWest.lat(),
        longitude: southWest.lng(),
      }),
      hi: new LatLng({
        latitude: northEast.lat(),
        longitude: northEast.lng(),
      }),
    });
    return layerQuery;
  }

  private createLayerCountQuery(filters?: FilterMap): LayerCountQuery {
    const [properties, tagNames, annotationFilter, imageAssociationFilter] =
      this.arrangeFilters(filters);
    return new LayerCountQuery({
      properties: properties,
      tagNames: tagNames,
      imageAnnotationFilter: annotationFilter || {},
      imageAssociationFilter: imageAssociationFilter || {},
    });
  }

  private arrangeFilters(
    filters?: FilterMap,
  ): [Property[], string[], ImageAnnotationFilter | null, ImageAssociationFilter | null] {
    let properties: Property[] = [];
    let tags: string[] = [];
    let annotationFilter: ImageAnnotationFilter | null = null;
    let imageAssociationFilter: ImageAssociationFilter | null = null;
    if (filters) {
      const tagFilter = filters[FilterField.TAG_NAMES];
      if (tagFilter?.size > 0) {
        tags = Array.from(tagFilter);
      }
      properties = convertToProperties(filters, [
        FilterField.TAG_NAMES,
        FilterField.IMAGE_GROUPS,
        FilterField.ANNOTATION_INCLUDE,
        FilterField.ANNOTATION_EXCLUDE,
        FilterField.IMAGE_ASSOCIATION_FILTER,
      ]);

      // If specified, filter to show images with annotations.
      const annotationFilters = filters[FilterField.IMAGE_GROUPS];
      if (annotationFilters?.size > 0) {
        if (!annotationFilter) {
          annotationFilter = new ImageAnnotationFilter();
        }
        const filterField = Array.from(annotationFilters)[0];
        if (filterField) {
          annotationFilter.labelBasedFilter.case = 'featureHasImagesWithAnnotations';
        }
        switch (filterField) {
          case AnnotationFilterOption.NO_ANNOTATIONS:
            annotationFilter.labelBasedFilter.value = false;
            break;
          case AnnotationFilterOption.ONE_OR_MORE_ANNOTATIONS:
            annotationFilter.labelBasedFilter.value = true;
            break;
          default:
            // Show all images by default (i.e. do not filter).
            break;
        }
      }

      const includeSet = filters[FilterField.ANNOTATION_INCLUDE];
      const excludeSet = filters[FilterField.ANNOTATION_EXCLUDE];
      const labels = new ImageAnnotationFilter_LabelFilter();

      if (includeSet?.size > 0) {
        if (!annotationFilter) {
          annotationFilter = new ImageAnnotationFilter();
        }
        const includeList: ImageAnnotationLabel[] = [];
        for (const str of includeSet) {
          const label = LABELS.find((l: {label: string}) => l.label === str);
          if (label) {
            includeList.push(label.value);
          }
        }
        labels.includesAny = includeList;
      }

      if (excludeSet?.size > 0) {
        if (!annotationFilter) {
          annotationFilter = new ImageAnnotationFilter();
        }
        const excludeList: ImageAnnotationLabel[] = [];
        for (const str of excludeSet) {
          const label = LABELS.find((l: LabelInfo) => l.label === str);
          if (label) {
            excludeList.push(label.value);
          }
        }
        labels.excludesAll = excludeList;
      }
      if (annotationFilter && (includeSet?.size > 0 || excludeSet?.size > 0)) {
        annotationFilter.labelBasedFilter.value = labels;
        annotationFilter.labelBasedFilter.case = 'combinedImageLabels';
      }

      const imageAssociationFilters = filters[FilterField.IMAGE_ASSOCIATION_FILTER];
      if (imageAssociationFilters?.size > 0) {
        if (!imageAssociationFilter) {
          imageAssociationFilter = new ImageAssociationFilter();
        }
        const options: ImageAssociationStatus[] = [];
        const filterOptions = Array.from(imageAssociationFilters);
        for (const filterOption of filterOptions) {
          const protoOption = ASSET_IMAGE_SOURCE_MAP.get(filterOption)!;
          if (protoOption !== undefined) {
            options.push(protoOption);
          }
        }
        imageAssociationFilter.sourceBasedFilter = options;
      }
    }
    return [properties, tags, annotationFilter, imageAssociationFilter];
  }

  private cacheFeature(feature: Feature | null) {
    if (feature === null) {
      return;
    }
    const featureId = feature.id;
    this.getCachedFeature(featureId).next(feature);
  }

  getCachedFeature(id: string): ReplaySubject<Feature> {
    if (!this.featureById.has(id)) {
      this.featureById.set(id, new ReplaySubject<Feature>(1));
    }
    return this.featureById.get(id)!;
  }

  private removeFeatureFromCache(feature: Feature | null) {
    if (feature === null) {
      return;
    }
    this.featureById.delete(feature.id);
  }
}
