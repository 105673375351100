import {BehaviorSubject, Observable, Subject, of} from 'rxjs';
import {catchError, first, switchMap, takeUntil} from 'rxjs/operators';

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

import {Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';
import {
  RelatedFeature,
  RelatedFeaturesGroup_RelatedFeatureRole,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/related_feature_pb.js';
import {Tag} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/tag_pb';

import {MONTH_YEAR_FORMAT} from '../../constants/common';
import {ASSETS_LAYER_ID, DEFECTS_LAYER_ID} from '../../constants/layer';
import {QUERY_PARAMS, ROUTE} from '../../constants/paths';
import {
  AnalyticsService,
  EventActionType,
  EventCategoryType,
} from '../../services/analytics_service';
import {ConfigService} from '../../services/config_service';
import {FeaturesService} from '../../services/features_service';
import {GalleryService} from '../../services/gallery_service';
import {GoogleMapsService} from '../../services/google_maps_service';
import {MapService} from '../../services/map_service';
import {UploadService} from '../../services/upload_service';
import {AnnotationEditorMode} from '../../typings/annotations';
import {getRelatedFeatures, getTakenOn} from '../../utils/image';
import {metadataToProperties} from '../../utils/metadata';
import {removeSensitiveProperties} from '../../utils/properties';

/**
 * Used in the app routing module to set the dynamic url parameter name.
 */
export const PHOTO_PARAM_KEY = 'key';

/**
 * Photo details page reads a photo key from the URL and fetches the photo
 * metadata which is then rendered to the page.
 */
@Component({
  selector: 'image-studio-details',
  templateUrl: 'image_studio_details.ng.html',
  styleUrls: ['image_studio_details.scss'],
})
export class ImageStudioDetails implements OnDestroy, OnInit {
  @Input() isNewUpload = false;
  @Input()
  set selectedImage(image: Image) {
    if (image !== null) {
      this.image = image;
      this.relatedAssetId = this.getRelatedAssetId(image);
      this.contextualRelatedFeature.next(this.getContextualRelatedFeatureFromImage(image));
      this.setUpDetails();
    }
  }
  image!: Image;
  relatedAssetId = '';

  // Used to expose the AnnotationEditorMode enum for use in the template.
  EDITOR_MODE = AnnotationEditorMode;
  // Affects the studio details to be displayed in shortened edit version.
  editorMode: AnnotationEditorMode = AnnotationEditorMode.OFF;

  destroyed = new Subject<void>();
  layerId = '';
  featureId = '';
  properties: Property[] = [];
  tags = new Set<string>();
  takenOn: Date | null = null;
  formattedAddress: string | null = null;
  showMoreDetails = false;

  // This subject contains a related feature associated with the current image
  // using `CONTEXTUAL_DEFECT` role. If no related feature found, it will contain null.
  readonly contextualRelatedFeature = new BehaviorSubject<RelatedFeature | null>(null);

  // This subject contains an image group (feature) returned by the `featuresService`
  // for the contextual related feature associated with the current image using
  // `CONTEXTUAL_DEFECT` role. This image group is used for navigating to the
  // inspection form.
  readonly contextualImageGroup: Observable<Feature | null> = this.contextualRelatedFeature.pipe(
    switchMap((relatedFeature: RelatedFeature | null) => {
      if (relatedFeature === null) {
        return of(null);
      }
      return this.featuresService.getFeature(relatedFeature.layerId, relatedFeature.id, false);
    }),
    catchError(() => {
      this.snackBar.open('Unable to get a contextual image group.', 'Close', {
        duration: 2500,
      });
      return of(null);
    }),
    takeUntil(this.destroyed),
  );

  readonly monthYearFormat = MONTH_YEAR_FORMAT;

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly configService: ConfigService,
    private readonly featuresService: FeaturesService,
    private readonly galleryService: GalleryService,
    private readonly googleMapsService: GoogleMapsService,
    private readonly mapService: MapService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly uploadService: UploadService,
  ) {}

  ngOnInit() {
    // This route fires one time and saves the feature id.
    this.route.queryParamMap
      .pipe(
        first(),
        switchMap((queryParamMap: ParamMap) => {
          this.layerId = queryParamMap.get(QUERY_PARAMS.LAYER_ID) || '';
          this.featureId = queryParamMap.get(QUERY_PARAMS.FEATURE_ID) || '';
          return this.featuresService.getFeature(this.layerId, this.featureId, false);
        }),
        takeUntil(this.destroyed),
      )
      .subscribe((feature: Feature | null) => {
        if (feature === null) {
          this.snackBar.open('Feature cannot be found.', 'Close', {
            duration: 2500,
          });
          return;
        }
      });

    this.galleryService
      .getEditorMode()
      .pipe(takeUntil(this.destroyed))
      .subscribe((mode: AnnotationEditorMode) => {
        this.editorMode = mode;
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  toggleMoreDetails() {
    this.showMoreDetails = !this.showMoreDetails;
  }

  setUpDetails() {
    this.properties = metadataToProperties(this.image.metadata);
    this.properties = removeSensitiveProperties(this.properties);
    this.takenOn = getTakenOn(this.image);
    this.getFormattedAddress(this.image)
      .pipe(takeUntil(this.destroyed))
      .subscribe((address: string | null) => {
        this.formattedAddress = address;
      });
    this.tags = this.getImageTags(this.image);
  }

  /**
   * Get the image tags that will be shown in the view.
   * @param image The image from which to get the tags.
   */
  private getImageTags(image: Image): Set<string> {
    return new Set(image.tags.map((t: Tag) => t.name));
  }

  onTagsUpdated(tags: Set<string>) {
    this.image.tags = [...tags].map((t: string) => new Tag({name: t}));
    // TODO: add tags update.
  }

  openInspectionForm(imageGroup: Feature) {
    if (this.configService.uploadFormImprovementsEnabled) {
      this.uploadService.renderUploadDialog({
        featureId: imageGroup.id,
        layerId: DEFECTS_LAYER_ID,
        edit: true,
      });
    } else {
      this.router.navigate([ROUTE.PHOTO_UPLOAD], {
        queryParams: {
          [QUERY_PARAMS.FEATURE_ID]: imageGroup.id,
          [QUERY_PARAMS.LAYER_ID]: DEFECTS_LAYER_ID,
          [QUERY_PARAMS.EDIT]: true,
          [QUERY_PARAMS.SOURCE_URL]: this.router.url,
        },
      });
    }
  }

  getFormattedAddress(image: Image): Observable<string | null> {
    const location = image.location && image.location!.location;
    if (!location) {
      return of(null);
    }
    return this.googleMapsService.getAddressFromLatLng({
      lat: location.latitude,
      lng: location.longitude,
    });
  }

  goBack() {
    this.router.navigate([ROUTE.LIGHTBOX], {
      queryParams: {
        [QUERY_PARAMS.LAYER_ID]: this.layerId,
        [QUERY_PARAMS.IMAGE_ID]: this.image.id,
        [QUERY_PARAMS.FEATURE_ID]: this.featureId,
        [QUERY_PARAMS.SOURCE_URL]: this.router.url,
      },
    });
  }

  updateImage(images: Image[]) {
    this.image = images[0];
    if (images.length === 0) {
      this.goBack();
    }
  }

  openLightbox() {
    this.analyticsService.sendEvent(EventActionType.LIGHTBOX_OPEN, {
      event_category: EventCategoryType.IMAGE,
      event_label: 'Photo page', // From whence the lightbox was opened.
    });
    this.router.navigate([ROUTE.LIGHTBOX], {
      queryParams: {
        [QUERY_PARAMS.LAYER_ID]: this.layerId,
        [QUERY_PARAMS.IMAGE_ID]: this.image?.id,
        [QUERY_PARAMS.SOURCE_URL]: this.router.url,
      },
    });
  }

  navigateToAsset() {
    this.mapService.setShouldRepositionMap(true);
    this.router.navigate([ROUTE.MAP, ASSETS_LAYER_ID, this.relatedAssetId]);
  }

  private getContextualRelatedFeatureFromImage(image: Image): RelatedFeature | null {
    const relatedDefects = getRelatedFeatures(
      image,
      RelatedFeaturesGroup_RelatedFeatureRole.CONTEXTUAL_DEFECT,
    );
    // We expect one contextual image group.
    if (relatedDefects.length === 1) {
      return relatedDefects[0];
    }
    return null;
  }

  private getRelatedAssetId(image: Image): string {
    const parentAssets = getRelatedFeatures(
      image,
      RelatedFeaturesGroup_RelatedFeatureRole.PARENT_ASSET,
    );

    return parentAssets.length > 0 ? parentAssets[0].id : '';
  }
}
