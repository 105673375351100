<div class="multiselect">
  <multiselect-autocomplete
    [placeholderText]="'Observation Code'"
    [options]="field.options"
    [autoFocus]="false"
    [allowEmpty]="true"
    (selectedOptionsChanged)="onSelectedOptionsChanged($event)">
  </multiselect-autocomplete>
</div>
@for (observation of observations.keys(); track $index) {
  <div class="observation">
    <div class="observation-code-and-description">
      <div class="observation-code">
        {{ observation.split('-')[0] }}
      </div>
      <div class="observation-code-descriptiion">
        {{ observation.split('-').slice(1).join('-') }}
      </div>
    </div>
    <div class="selections">
      <mat-form-field class="input" appearance="outline">
        <mat-label>Select priority level</mat-label>
        <mat-select
          (selectionChange)="onSubSelectionChanged($event, observation, 'Priority Level')"
          [required]="true">
          @for (option of priorityLevels; track option) {
            <mat-option [value]="option">
              {{ option }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field class="input" appearance="outline">
        <mat-label>Select network level</mat-label>
        <mat-select
          (selectionChange)="onSubSelectionChanged($event, observation, 'Network Level')"
          [required]="true">
          @for (option of netWorkLevels; track option) {
            <mat-option [value]="option">
              {{ option }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>
}
