import {BehaviorSubject, Observable, first, map, mergeMap, of} from 'rxjs';

import {Injectable} from '@angular/core';
import {ParamMap, Router} from '@angular/router';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';
import {RelatedFeaturesGroup_RelatedFeatureRole} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/related_feature_pb';

import {SortByOption} from '../constants/gallery';
import {ASSETS_LAYER_ID, AUTOTOP_LAYER_ID, DEFECTS_LAYER_ID} from '../constants/layer';
import {QUERY_PARAMS, ROUTE} from '../constants/paths';
import {AnnotationEditorMode} from '../typings/annotations';
import {ColorScheme} from '../typings/common';
import {dateToDateStringWithMonthLevelFormat} from '../utils/date';
import {getTakenOn, prefetchLidarImage} from '../utils/image';
import {getRelatedFeatures} from '../utils/image';
import {sortImageGroupWithCaptureTimeFallback} from '../utils/sort';
import {FeaturesService} from './features_service';
import {UserPreferencesService} from './user_preferences_service';

interface ImageGroupInfo {
  images: Image[];
  uploadTimestamp: Date;
}

interface CapturedTimeGroupInfo {
  images: Image[];
}

interface ImageGroupingResult<T> {
  groupedImages: Map<string, T>;
  ungroupedImages: Image[];
}

/**
 * Handles getting, posting and sorting asset photos.
 */
@Injectable()
export class GalleryService {
  featureId = '';
  layerId = '';
  imageId = '';

  // Whether the metadata can be edited. If true, an icon will be visible that
  // routes to the upload page.
  canEditMetadata = new BehaviorSubject<boolean>(false);
  // The route for editing metadata.
  editMetadataRoute = new BehaviorSubject<string>('');
  // Updates on editor mode.
  editorMode = new BehaviorSubject<AnnotationEditorMode>(AnnotationEditorMode.OFF);

  images = new BehaviorSubject<Image[]>([]);
  selectedImage = new BehaviorSubject<Image | null>(null);

  newImageSelected = new BehaviorSubject<string>('');

  // A map of images by their respective IDs. When an image ID is read through
  // the URL, this cache will be checked first before fetching a new image.
  imageById = new Map<string, Image>();

  // A map of indexes by image IDs. The index represents the position in an
  // array of images. This is used for getting the next or previous image when
  // requested via the lightbox and replacing an image when it is modified.
  indexById = new Map<string, number>();

  // A map of image group label by group IDs. Labels are derived from image
  // group's upload time and will be in the format "MMM yyyy". Labels are displayed under grouped
  // images in the asset timeline.
  imageGroupLabelById = new Map<string, string>();

  // A set of all image ids that have successfully prefetched a LiDAR image.
  lidarExistsById = new Set<string>();

  // If specified as 'true' via a URL query parameter, hide the carousel. Note
  // this differs from enabling the carousel entirely.
  hideCarousel: string | null = null;

  private layersWithAssetTimelineEnabled = [ASSETS_LAYER_ID, AUTOTOP_LAYER_ID];

  private previousEditorMode: AnnotationEditorMode = AnnotationEditorMode.OFF;

  // Emits when a LiDAR image is loaded.
  readonly lidarImageLoaded = new BehaviorSubject<string>('');

  // Updates on the indicator of the new upload. New upload in gallery means
  // that the target image has not yet been uploaded and instead comes from the
  // pending store.
  private readonly isNewUpload = new BehaviorSubject<boolean>(false);

  // Color scheme set by user.
  private previousColorScheme: ColorScheme | null = null;

  // Color scheme of the editor.
  private colorScheme!: ColorScheme;

  private selectedSortByOption = SortByOption.CAPTURED_TIME;

  private readonly imagesSortedByOption = new Map<SortByOption, Image[]>();

  constructor(
    private readonly router: Router,
    private readonly userPreferencesService: UserPreferencesService,
    private readonly featuresService: FeaturesService,
  ) {
    this.userPreferencesService.getColorScheme().subscribe((colorScheme) => {
      this.colorScheme = colorScheme;
    });
  }

  setProperties(queryParamMap: ParamMap) {
    this.layerId = queryParamMap.get(QUERY_PARAMS.LAYER_ID)!;
    this.featureId = queryParamMap.get(QUERY_PARAMS.FEATURE_ID)!;
    this.hideCarousel = queryParamMap.get(QUERY_PARAMS.HIDE_CAROUSEL);
    this.imageId = queryParamMap.get(QUERY_PARAMS.IMAGE_ID)!;
  }

  updateImages(images: Image[]) {
    this.images.next(images);
    this.cacheImagesAndIndexes(images);
  }

  updateSelectedImage(image: Image | null) {
    this.imageId = image?.id ?? '';
    this.selectedImage.next(image);
  }

  selectImage(image: Image, sourceUrl: string) {
    this.updateSelectedImage(image);
    this.newImageSelected.next(sourceUrl);
  }

  selectNextImage(sourceUrl: string) {
    if (!this.indexById.has(this.selectedImage.getValue()!.id)) {
      return;
    }
    const newIndex =
      (this.indexById.get(this.selectedImage.getValue()!.id)! + 1) % this.images.getValue().length;
    this.selectImage(this.images.getValue()[newIndex], sourceUrl);
  }

  selectPreviousImage(sourceUrl: string) {
    if (!this.indexById.has(this.selectedImage.getValue()!.id)) {
      return;
    }
    const currentIndex = this.indexById.get(this.selectedImage.getValue()!.id)!;
    const newIndex = currentIndex === 0 ? this.images.getValue().length - 1 : currentIndex - 1;
    this.selectImage(this.images.getValue()[newIndex], sourceUrl);
  }

  cacheImagesAndIndexes(images: Image[]) {
    this.imageById = new Map<string, Image>();
    this.indexById = new Map<string, number>();
    for (const [i, image] of images.entries()) {
      this.imageById.set(image.id, image);
      this.indexById.set(image.id, i);
    }
  }

  updateCanEditMetadata(canEditMetadata: boolean) {
    this.canEditMetadata.next(canEditMetadata);
  }

  /**
   * Builds the upload route for the defect. Updating the metadata from there
   * will update the metadata for all the images associated with said defect.
   */
  buildEditImageMetadataRoute() {
    const urlTree = this.router.createUrlTree([ROUTE.PHOTO_UPLOAD], {
      queryParams: {
        [QUERY_PARAMS.EDIT]: true,
        [QUERY_PARAMS.LAYER_ID]: this.layerId,
        [QUERY_PARAMS.FEATURE_ID]: this.featureId,
        [QUERY_PARAMS.SOURCE_URL]: this.router.url,
      },
    });
    this.editMetadataRoute.next(this.router.serializeUrl(urlTree));
  }

  prefetchLidarImages(images: Image[]) {
    const observables = images.map((image) => prefetchLidarImage(image));
    observables.forEach((obs) => {
      obs.subscribe({
        next: (id: string) => {
          this.lidarExistsById.add(id);
          this.lidarImageLoaded.next(id);
        },
        error: () => {
          console.error('failed to prefetch LiDAR imagery');
        },
      });
    });
  }

  setEditorMode(mode: AnnotationEditorMode) {
    if (this.previousEditorMode !== mode) {
      if (
        this.previousEditorMode === AnnotationEditorMode.OFF &&
        this.colorScheme === ColorScheme.LIGHT
      ) {
        // Only change theme to dark on entering edit mode.
        this.previousColorScheme = this.colorScheme;
        this.toggleTheme();
      } else if (
        mode === AnnotationEditorMode.OFF &&
        this.previousColorScheme &&
        this.colorScheme !== this.previousColorScheme
      ) {
        // Only change theme back to light on exiting the edit mode if it was configured initially.
        this.toggleTheme();
        this.previousColorScheme = null;
      }
    }
    this.previousEditorMode = mode;
    this.editorMode.next(mode);
  }

  getEditorMode(): Observable<AnnotationEditorMode> {
    return this.editorMode.asObservable();
  }

  setIsNewUpload(isNewUpload: boolean) {
    this.isNewUpload.next(isNewUpload);
  }

  getIsNewUpload(): Observable<boolean> {
    return this.isNewUpload.asObservable();
  }

  checkLayerSupportsAssetTimeline() {
    return this.layersWithAssetTimelineEnabled.includes(this.layerId);
  }

  getSelectedSortByOption(): SortByOption {
    return this.selectedSortByOption;
  }

  getDefaultGroupedImages(images: Image[]): Observable<Image[]> {
    switch (this.selectedSortByOption) {
      case SortByOption.CAPTURED_TIME:
        return this.getImagesGroupedByCapturedTime(images);
      case SortByOption.IMAGE_GROUP:
        return this.getImagesGroupedByContextualDefect(images);
      default:
        console.error('Invalid sort option.');
        throw new Error();
    }
  }

  updateImagesBasedOnSortByOption(option: SortByOption) {
    this.selectedSortByOption = option;
    let getImages: Observable<Image[]>;
    switch (this.selectedSortByOption) {
      case SortByOption.CAPTURED_TIME:
        getImages = this.getImagesGroupedByCapturedTime();
        break;
      case SortByOption.IMAGE_GROUP:
        getImages = this.getImagesGroupedByContextualDefect();
        break;
      default:
        console.error('Invalid sort option.');
        throw new Error();
    }
    getImages.pipe(first()).subscribe((images: Image[]) => this.updateImages(images));
  }

  getImagesGroupedByContextualDefect(images = this.images.getValue()): Observable<Image[]> {
    if (this.imagesSortedByOption.has(SortByOption.IMAGE_GROUP)) {
      return of(this.imagesSortedByOption.get(SortByOption.IMAGE_GROUP)!);
    }
    return of(this.groupImagesByContextualDefect(images)).pipe(
      mergeMap(
        (
          imageGroupingResult: ImageGroupingResult<ImageGroupInfo>,
        ): Observable<ImageGroupingResult<ImageGroupInfo>> => {
          // Fetch feature data for all the unique contextual defects. This is required to obtain
          // upload timestamp of the image groups which will be used for sorting the groups and
          // building formatted label for the groups to display in the asset timeline.
          return this.featuresService
            .getFeaturesByIds(
              DEFECTS_LAYER_ID,
              Array.from(imageGroupingResult.groupedImages.keys()),
            )
            .pipe(
              map((defects: Feature[]): ImageGroupingResult<ImageGroupInfo> => {
                const imageGroupInfoById = imageGroupingResult.groupedImages;
                for (const defect of defects) {
                  const imageGroupId = defect.id;
                  const imageGroup = imageGroupInfoById.get(imageGroupId)!;
                  imageGroup.uploadTimestamp = this.extractImageGroupUploadTime(
                    defect,
                    imageGroup.images,
                  );
                  this.imageGroupLabelById.set(
                    imageGroupId,
                    dateToDateStringWithMonthLevelFormat(imageGroup.uploadTimestamp),
                  );
                }
                return imageGroupingResult;
              }),
            );
        },
      ),
      map((imageGroupingResult: ImageGroupingResult<ImageGroupInfo>): Image[] => {
        let imageGroupInfoById = imageGroupingResult.groupedImages;
        const ungroupedImages = imageGroupingResult.ungroupedImages;

        // Sort the image groups based on the upload timestamp.
        imageGroupInfoById = new Map(
          [...imageGroupInfoById.entries()].sort(
            (group1, group2) =>
              group2[1].uploadTimestamp.getTime() - group1[1].uploadTimestamp.getTime(),
          ),
        );

        this.sortImagesWithinGroup(imageGroupInfoById);

        const aggregatedImages = this.aggregateImagesInGroups(imageGroupInfoById, ungroupedImages);
        this.imagesSortedByOption.set(SortByOption.IMAGE_GROUP, aggregatedImages);
        return aggregatedImages;
      }),
    );
  }

  getImagesGroupedByCapturedTime(images = this.images.getValue()): Observable<Image[]> {
    if (this.imagesSortedByOption.has(SortByOption.CAPTURED_TIME)) {
      return of(this.imagesSortedByOption.get(SortByOption.CAPTURED_TIME)!);
    }

    // Group images based on captured timestamp.
    const capturedTimeGroupingResult = this.groupImagesByCapturedTime(images);
    let capturedTimeGroupInfoByLabel = capturedTimeGroupingResult.groupedImages;
    const ungroupedImages = capturedTimeGroupingResult.ungroupedImages;

    // Sort the groups based on captured timestamp from latest to oldest. Keys of
    // capturedTimeGroupInfoByLabel are formatted date string labels and represent the captured
    // timestamp of corresponding groups.
    capturedTimeGroupInfoByLabel = new Map(
      [...capturedTimeGroupInfoByLabel.entries()].sort(
        (group1, group2) => Date.parse(group2[0]) - Date.parse(group1[0]),
      ),
    );

    // Perform sorting of images within each group.
    this.sortImagesWithinGroup(capturedTimeGroupInfoByLabel);

    // Aggregate images from all the groups together along with ungrouped images.
    const aggregatedImages = this.aggregateImagesInGroups(
      capturedTimeGroupInfoByLabel,
      ungroupedImages,
    );
    this.imagesSortedByOption.set(SortByOption.CAPTURED_TIME, aggregatedImages);
    return of(aggregatedImages);
  }

  private toggleTheme() {
    this.userPreferencesService.setColorScheme(
      this.colorScheme === ColorScheme.DARK ? ColorScheme.LIGHT : ColorScheme.DARK,
    );
  }

  private extractImageGroupUploadTime(groupFeature: Feature, groupImages: Image[]): Date {
    // An image group's created timestamp is equivalent to its upload timestamp.
    if (groupFeature.createdAt) {
      return groupFeature.createdAt!.toDate();
    }

    let oldestUploadTimestamp = new Date();
    for (const image of groupImages) {
      if (image.uploadedAt && image.uploadedAt!.toDate() < oldestUploadTimestamp) {
        oldestUploadTimestamp = image.uploadedAt!.toDate();
      }
    }
    return oldestUploadTimestamp;
  }

  private groupImagesByContextualDefect(images: Image[]): ImageGroupingResult<ImageGroupInfo> {
    const imageGroupInfoById = new Map<string, ImageGroupInfo>();
    const ungroupedImages: Image[] = [];
    for (const image of images) {
      const [contextualDefect] = getRelatedFeatures(
        image,
        RelatedFeaturesGroup_RelatedFeatureRole.CONTEXTUAL_DEFECT,
      );
      if (contextualDefect) {
        const contextualDefectId = contextualDefect.id;
        if (!imageGroupInfoById.has(contextualDefectId)) {
          imageGroupInfoById.set(contextualDefectId, {
            uploadTimestamp: new Date(),
            images: [],
          });
        }
        imageGroupInfoById.get(contextualDefectId)!.images.push(image);
      } else {
        ungroupedImages.push(image);
      }
    }
    return {
      groupedImages: imageGroupInfoById,
      ungroupedImages: ungroupedImages,
    };
  }

  private groupImagesByCapturedTime(images: Image[]): ImageGroupingResult<CapturedTimeGroupInfo> {
    const capturedTimeGroupInfoByLabel = new Map<string, CapturedTimeGroupInfo>();
    const ungroupedImages: Image[] = [];
    for (const image of images) {
      const takenOnTimestamp = getTakenOn(image);
      if (!takenOnTimestamp) {
        ungroupedImages.push(image);
        continue;
      }
      const groupLabel = dateToDateStringWithMonthLevelFormat(takenOnTimestamp!);
      if (!capturedTimeGroupInfoByLabel.has(groupLabel)) {
        capturedTimeGroupInfoByLabel.set(groupLabel, {images: []});
      }
      capturedTimeGroupInfoByLabel.get(groupLabel)!.images.push(image);
    }
    return {
      groupedImages: capturedTimeGroupInfoByLabel,
      ungroupedImages: ungroupedImages,
    };
  }

  private sortImagesWithinGroup(groupInfoById: Map<string, {images: Image[]}>) {
    // Sort images within the groups based on bearing with fallback to captured timestamp.
    for (const [groupId, groupInfo] of groupInfoById) {
      groupInfo.images = sortImageGroupWithCaptureTimeFallback(groupInfo.images);
      groupInfoById.set(groupId, groupInfo);
    }
  }

  private aggregateImagesInGroups(
    groupInfoById: Map<string, {images: Image[]}>,
    ungroupedImages: Image[],
  ): Image[] {
    // Aggregate all the images from all the groups in order. Images that do not
    // belong to any group will be placed at the end of the aggregation.
    const imagesOrderedByGroups = [];
    for (const groupInfo of groupInfoById.values()) {
      imagesOrderedByGroups.push(...groupInfo.images);
    }
    imagesOrderedByGroups.push(...ungroupedImages);
    return imagesOrderedByGroups;
  }
}
