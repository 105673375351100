@if (pendingFiles?.length > 0 || existingVisibleImages?.length > 0) {
  <div class="thumbnail-row">
    @for (pendingFile of pendingFiles; track pendingFile) {
      <div class="thumbnail-container">
        <image-box
          [id]="pendingFile.id"
          [url]="pendingFile.url"
          [name]="pendingFile.file.name"
          [isNewUpload]="true"
          (onDelete)="deletePendingFile(pendingFile)">
        </image-box>
      </div>
    }
    @for (image of existingVisibleImages; track image) {
      <div class="thumbnail-container">
        <image-box
          [id]="image.id"
          [url]="imageUrlsById.get(image.id)"
          [name]="image.name"
          [isNewUpload]="false"
          (onDelete)="queueImageDeletion(image)">
        </image-box>
      </div>
    }
  </div>
}
<div class="select-photo-header">
  <button
    mat-stroked-button
    (click)="selectPhoto()"
    class="action-button select"
    matTooltip="Select photos"
    type="button"
    i18n-aria-label
    aria-label="Select photo">
    <mat-icon class="upload-icon">add_a_photo</mat-icon>
    {{ pendingFiles.length === 0 ? 'Add photos' : 'Update photos' }}
  </button>
  <input
    ngModel
    #photoSelector
    accept="image/*"
    type="file"
    style="display: none"
    aria-hidden="true"
    (change)="getFile($event)"
    multiple
    required
    name="photos" />
</div>
<form class="form" #photoForm="ngForm" (ngSubmit)="submitForm()" (keydown)="onFormKeyPress($event)">
  @for (section of formTemplate.sections; track section; let last = $last) {
    <div [ngClass]="{section: true, 'last-section': last}">
      @for (field of section.fields; track field; let i = $index) {
        @if (field.header && field.header.length > 0) {
          <div class="field-header">
            {{ field.header }}
          </div>
        }
        @if (field.subheader && field.subheader.length > 0) {
          <div class="field-subheader">
            {{ field.subheader }}
          </div>
        }
        @if (field.type == FieldType.ANNOTATION_SUMMARY) {
          <div class="summary-container">
            @if (imageIds.length > 0) {
              <annotation-summary [imageIds]="imageIds"> </annotation-summary>
            }
          </div>
        }
        @if (field.type === FieldType.SELECTION) {
          <mat-form-field class="input" appearance="outline">
            <mat-label>{{ field.placeholder }}</mat-label>
            <mat-select
              [name]="field.propertyName"
              [(ngModel)]="properties[field.propertyName]"
              [required]="field.required">
              @for (option of field.options; track option) {
                <mat-option [value]="option">
                  {{ option }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
        @if (field.type === FieldType.CHECKBOX) {
          <section
            [formGroup]="formGroupByPropertyName.get(field.propertyName)"
            class="input"
            appearance="outline">
            <mat-label>{{ field.placeholder }}</mat-label>
            @for (option of field.options; track option) {
              <div>
                <mat-checkbox [formControlName]="option" [value]="option" class="checkbox">
                  {{ option }}
                </mat-checkbox>
              </div>
            }
          </section>
        }
        @if (field.type === FieldType.TEXT) {
          <mat-form-field class="input" appearance="outline">
            <mat-label>{{ field.placeholder }}</mat-label>
            <input
              matInput
              [(ngModel)]="properties[field.propertyName]"
              type="text"
              autocorrect="off"
              autocomplete="off"
              spellcheck="false"
              [name]="field.propertyName"
              [required]="field.required" />
          </mat-form-field>
        }
        @if (field.type === FieldType.NUMBER) {
          <mat-form-field class="input" appearance="outline">
            <mat-label>{{ field.placeholder }}</mat-label>
            <input
              matInput
              type="number"
              [name]="field.propertyName"
              [(ngModel)]="properties[field.propertyName]"
              [required]="field.required" />
          </mat-form-field>
        }
        @if (field.type === FieldType.TEXTAREA) {
          <mat-form-field class="input" appearance="outline">
            <mat-label>{{ field.placeholder }}</mat-label>
            <textarea
              matInput
              [maxLength]="320"
              rows="4"
              cdkTextareaAutosize
              cdkAutosizeMinRows="4"
              autocorrect="off"
              autocomplete="off"
              spellcheck="false"
              [name]="field.propertyName"
              [(ngModel)]="properties[field.propertyName]"
              [required]="field.required">
            </textarea>
          </mat-form-field>
        }
        @if (field.type === FieldType.MULTISELECT) {
          <observation-input
            [field]="field"
            (completeStatusChanged)="onMultitieredCompleteStatusChanged($event)"
            (propertiesSet)="onPropertiesSet($event)"></observation-input>
        }
        @if (field.type == FieldType.BINARY) {
          <div class="input">
            <label [id]="'radio-label-' + i">
              {{ field.placeholder }}
            </label>
            <mat-radio-group
              class="radio-group"
              [attr.aria-labelledby]="'radio-label-' + i"
              [name]="field.propertyName"
              [(ngModel)]="properties[field.propertyName]"
              [required]="field.required">
              <mat-radio-button class="radio-button" value="yes"> Yes </mat-radio-button>
              <mat-radio-button class="radio-button" value="no"> No </mat-radio-button>
            </mat-radio-group>
          </div>
        }
        @if (!offline && field.type == FieldType.MAP) {
          <div class="map-container">
            <feature-selection-map
              [locationDescriptionVisible]="formTemplate.includeLocationDescription"
              [locationDescription]="properties[globalPropertyName.LOCATION_DESCRIPTION] || ''"
              (locationDescriptionChange)="
                updateProperty(globalPropertyName.LOCATION_DESCRIPTION, $event)
              "
              [initialMapMetadata]="initialMapMetadata"
              [layerIds]="[mapSelectionLayerId]"
              (featureSelected)="onFeatureSelected($event)"
              (locationPinChanged)="onLocationPinChanged($event)">
            </feature-selection-map>
          </div>
        }
        @if (!offline && field.type == FieldType.TAGS) {
          <div class="tags-container">
            <div class="label">Tags</div>
            <div class="value-container">
              <tags [tags]="tags" [layerId]="layerId" (tagsUpdated)="onTagsUpdated($event)"> </tags>
            </div>
          </div>
        }
        @if (!offline && field.type === FieldType.PROPERTY_LOOKUP) {
          <div>
            <div class="property-lookup">
              <div class="property-lookup-text">
                {{ displayPropertyLookup(field.propertiesToLookup || []) }}
              </div>
              @for (feature of propertyLookupFeatures; track feature) {
                <div class="property-lookup-features">
                  <div class="property-lookup-row">
                    <div>
                      {{ feature.name }}
                    </div>
                    <a [routerLink]="createMapFeaturePath(feature)" target="_blank">
                      <mat-icon class="icon">open_in_new</mat-icon>
                    </a>
                  </div>
                </div>
              }
              @if (propertyLookupFeaturesAreLoading) {
                <mat-progress-spinner mode="indeterminate" color="primary" diameter="24">
                </mat-progress-spinner>
              }
            </div>
          </div>
        }
      }
    </div>
  }
  @if (offline) {
    <offline-search
      placeholder="Asset ID"
      [assetId]="initialFormData?.asset?.id"
      (optionSelected)="externalId = $event">
    </offline-search>
  }
  @if (editing) {
    <button
      mat-raised-button
      color="primary"
      [disabled]="!isFormValid(true)"
      type="submit"
      class="action-button upload">
      Save changes
    </button>
  }
  @if (!editing) {
    <button
      mat-raised-button
      color="primary"
      [disabled]="!isFormValid(false)"
      type="submit"
      class="action-button upload">
      Upload image
    </button>
  }
</form>
