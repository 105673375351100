// TODO(reubenn): Deprecate enum PropertyKey.
/**
 * Feature property keys.
 */
export enum PropertyKey {
  DESCRIPTION = 'Description',
  FEEDER_ID = 'Feeder ID',
  EQUIPMENT_ID = 'Equipment ID',
  FORM_TYPE = 'Form type',
  FORMATTED_ADDRESS = 'Address',
  LOCATION = 'Location',
  LOCATION_DESCRIPTION = 'Location description',
  PRIORITY = 'Priority',
  DATE_UPLOADED = 'Date uploaded',
  USER = 'User',
  WORK_REQUIRED = 'Work required',
  MATERIAL_IDS = 'Material IDs',
  OBSERVATION_CODES = 'Observation Codes',
  OBSERVATION_PRIORITY_LEVELS = 'Observation Priority Levels',
  OBSERVATION_NETWORK_LEVELS = 'Observation Network Levels',
}

/**
 * Property names that do not exist on the form but will be added at some point
 * during form upload.
 */
export enum NonFormPropertyName {
  ADDRESS = 'Address',
  DATE_UPLOADED = 'Date uploaded',
  USER = 'User',
}

/**
 * A Regex that matches sensitive property keys.
 */
export const SENSITIVE_KEYS_PATTERN = /user|owner|email/i;

/**
 * Keys that should not be considered sensitive.
 */
export const EXCLUDED_SENSITIVE_KEYS = new Set(['user', 'owner']);

/**
 * This enum contains possible values of the `Inspection status` property
 * used for tracking inspection status of the Image Groups.
 */
export enum InspectionStatus {
  NOT_INSPECTED = 'Not Inspected',
  INSPECTED = 'Inspected',
}
