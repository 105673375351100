import {Timestamp} from '@bufbuild/protobuf';
import {Observable, firstValueFrom, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';

import {Clipboard} from '@angular/cdk/clipboard';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

import {
  FeatureIdMap,
  Property,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';

import {DISPLAY_NAME_BY_TOGO} from '../constants/image';
import {ROUTE} from '../constants/paths';
import {FeaturesService} from '../services/features_service';
import {LayersService} from '../services/layers_service';
import {dateToDateStringWithMonthLevelFormat, timestampToDateTimeString} from '../utils/date';
import {
  isCaptureDateField,
  mergePropertyValuesWithSameKey,
  removeEmptyAndIgnoredProperties,
} from '../utils/properties';

// Need this so that the minifier does minify these properties in OnChanges.
declare interface MetadataPropertyChanges extends SimpleChanges {
  properties: SimpleChange;
}

/**
 * Metadata about a property.
 */
export interface PropertyInfo {
  displayName: string;
  value: string;
  property: Property;
  allowValueCopying: boolean;
  isLink: boolean;
  containsId: Promise<boolean>;
}

// Collection of property names to link.
// TODO(b/199801170) revisit the linking logic
const LINKABLE_PROPERTIES = new Set<string>(['Parent Work Order', 'Pole Number', 'vid']);

// Add a property name to this set if you would like it to have a copy action.
const COPY_ICON_SET = new Set<string>(['Detail Sheet']);
const DEFAULT_INITIAL_NUMBER_OF_PROPERTIES_TO_SHOW = 4;

/**
 * Renders key value properties with an action icon if it exists.
 */
@Component({
  selector: 'metadata',
  templateUrl: './metadata.ng.html',
  styleUrls: ['./metadata.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Metadata implements OnChanges {
  @Input() properties: Property[] = [];
  @Input() featureById: Map<string, GeoJSON.Feature> | null = null;
  @Input() selectedFeature: string | null = null;
  @Input() layerId: string | null = null;

  expanded = false;
  totalNumberOfProperties: number = 0;
  /**
   * Holds a value that represents whether an internal id exists based on an
   * external ID. This is required because otherwise idExists, which emits into
   * AsyncPipe, ends up in a loop.
   */
  idExistsByExternalId = new Map<string, boolean>();
  idByExternalId = new Map<string, string>();
  initialNumberOfPropertiesToShow = DEFAULT_INITIAL_NUMBER_OF_PROPERTIES_TO_SHOW;
  numberOfPropertiesToShow = DEFAULT_INITIAL_NUMBER_OF_PROPERTIES_TO_SHOW;
  propertiesInfo: PropertyInfo[] = [];

  constructor(
    private readonly clipboard: Clipboard,
    private readonly featuresService: FeaturesService,
    private readonly layersService: LayersService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
  ) {}

  ngOnChanges({properties}: MetadataPropertyChanges) {
    this.expanded = false;
    if (properties) {
      const filteredProperties = this.removeEmptyAndIgnoredProperties(properties.currentValue);
      filteredProperties.sort((propA: Property, propB: Property): number => {
        return caseInsensitiveAlphaSort(propA.key, propB.key);
      });
      this.properties = mergePropertyValuesWithSameKey(filteredProperties);
      this.properties = this.setPrioritizedProperties(this.properties);
      this.totalNumberOfProperties = this.properties.length;
      this.updatePropertiesInfo();
    }
  }

  copyPropertyValue(property: Property) {
    const value = `${property.propertyValue.value || ''}`;
    this.clipboard.copy(value);
    this.snackBar.open(`${value} copied to clipboard.`, '', {duration: 2500});
  }

  /**
   * Navigates to a feature based on an external ID.
   */
  navigateByProperty(externalIdProperty: Property) {
    const featureId =
      externalIdProperty.propertyValue.case === 'value'
        ? this.idByExternalId.get(externalIdProperty.propertyValue.value)
        : '';
    if (!featureId) {
      return;
    }
    this.router.navigate([ROUTE.MAP, this.layerId, featureId]);
  }

  toggleMetadataVisibility() {
    this.expanded = !this.expanded;

    this.numberOfPropertiesToShow = this.expanded
      ? this.totalNumberOfProperties
      : this.initialNumberOfPropertiesToShow;
  }

  private setPrioritizedProperties(properties: Property[]) {
    const priorityPropertyKeys = this.layerId
      ? this.layersService.getLayerStyle(this.layerId)?.priorityPropertyKeys
      : '';
    if (!priorityPropertyKeys || priorityPropertyKeys.length === 0) {
      return properties;
    }
    const prioritySet = new Set<string>(priorityPropertyKeys);
    const head: Property[] = [];
    const tail: Property[] = [];
    for (const property of properties) {
      if (prioritySet.has(property.key)) {
        head.push(property);
        continue;
      }
      tail.push(property);
    }
    this.initialNumberOfPropertiesToShow = head.length;
    this.numberOfPropertiesToShow = head.length;
    return [...head, ...tail];
  }

  /**
   * Remove empty properties so that they aren't displayed in the view.
   */
  private removeEmptyAndIgnoredProperties(properties: Property[]): Property[] {
    const ignorePropertyKeys = this.layerId
      ? this.layersService.getLayerStyle(this.layerId)?.ignorePropertyKeys
      : null;
    return removeEmptyAndIgnoredProperties(properties, ignorePropertyKeys || []);
  }

  /**
   * Determines whether or not a property should be a link. Linked properties
   * exist in LINKABLE_PROPERTIES and have values that aren't
   * the current feature.
   */
  private shouldBeLink(property: Property): boolean {
    const value = property.propertyValue.case === 'value' ? property.propertyValue.value : '';
    return LINKABLE_PROPERTIES.has(property.key) && this.selectedFeature !== value;
  }

  /**
   * Determines whether an asset ID or work order ID exists and can therefore
   * be linked to.
   */
  private idExists(idProperty: Property): Observable<boolean> {
    const id = idProperty.propertyValue.case === 'value' ? idProperty.propertyValue.value : '';
    if (!id) {
      return of(false);
    }
    if (this.idExistsByExternalId.has(id)) {
      return of(this.idExistsByExternalId.get(id)!);
    }
    return this.featuresService.getFeatureIds([id]).pipe(
      mergeMap((featureIds: FeatureIdMap[]) => {
        if (featureIds.length === 0) {
          this.idExistsByExternalId.set(id, false);
          return of(false);
        }
        this.idByExternalId.set(id, featureIds[0].id);
        this.idExistsByExternalId.set(id, true);
        return of(true);
      }),
      catchError(() => {
        return of(false);
      }),
    );
  }

  private getPropertyValue(property: Property): string {
    const propertyValue = property.propertyValue.value,
      propertyValueType = property.propertyValue.case;
    if (!propertyValue) {
      return '';
    }

    // Re-format capture date property to month + year format as a part of the solution to comply
    // with Geo's privacy requirements.
    if (isCaptureDateField(property.key)) {
      const capturedDate =
        propertyValueType === 'timestampValue'
          ? (propertyValue as Timestamp).toDate()
          : new Date(propertyValue as string);
      return dateToDateStringWithMonthLevelFormat(capturedDate);
    }
    if (propertyValueType === 'timestampValue') {
      return timestampToDateTimeString(propertyValue as Timestamp);
    }
    return propertyValue as string;
  }

  private propertyDisplayName(property: string): string {
    return DISPLAY_NAME_BY_TOGO.get(property) || property;
  }

  private updatePropertiesInfo() {
    this.propertiesInfo = this.properties.map(
      (property: Property): PropertyInfo => ({
        displayName: this.propertyDisplayName(property.key),
        value: this.getPropertyValue(property),
        property: property,
        allowValueCopying: COPY_ICON_SET.has(property.key) && !!property.propertyValue.value,
        isLink: this.shouldBeLink(property),
        containsId: firstValueFrom(this.idExists(property)),
      }),
    );
  }
}

function caseInsensitiveAlphaSort(a: string, b: string): number {
  a = a.toLowerCase();
  b = b.toLowerCase();
  return a < b ? -1 : a > b ? 1 : 0;
}
