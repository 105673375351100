import {Injectable} from '@angular/core';

import {ConfigParams, ConfigService} from './config_service';
import {UserPreferencesService} from './user_preferences_service';

/**
 * GridAware-specific event categories.
 */
export enum EventCategoryType {
  ERROR = 'Error',
  FILTER_VIEW = 'Filter view',
  FILE = 'File',
  FORMS = 'Forms',
  IMAGE = 'Image',
  MAP = 'Map',
  TABLE = 'Table',
  TIMELINE = 'Timeline',
}

/**
 * GridAware-specific event types.
 *
 * For consistency, please use lowercase event names and follow the GA4 event
 * naming rules: "Event names must start with a letter. Use only letters,
 * numbers, and underscores. Don't use spaces."
 * https://support.google.com/analytics/answer/13316687?hl=en
 */
export enum EventActionType {
  ADD_FILTER = 'filter_added',
  ANNOTATION_FILTER_ADD = 'added_annotation_filter',
  IMAGE_ASSOCIATION_FILTER_ADD = 'added_image_association_filter',
  AUTH_FAILURE = 'auth_failure',
  ASSET_TYPE_FILTER = 'asset_types_filtered',
  ASSET_LINE_FILTER = 'asset_lines_filtered',
  CHANGE_FILTER = 'filter_changed',
  CLICK = 'click',
  FEATURE_SELECTED = 'feature_selected',
  FILTER_VIEW_SELECTED = 'filter_view_selected',
  FORM_UPLOADED = 'form_uploaded',
  FORM_UPLOAD_FAILED = 'form_upload_failed',
  IMAGE_ANNOTATED_ANNOTATIONS_TOTAL_COUNT = 'image_annotated_annotations_total_count',
  IMAGE_ANNOTATED = 'image_annotated',
  IMAGE_ANNOTATION_CORRECTED = 'image_annotation_corrected',
  IMAGE_ANNOTATION_CORRECTED_FAILED = 'image_annotation_corrected_failed',
  IMAGE_ANNOTATION_FAILED = 'image_annotation_failed',
  IMAGE_UPLOADED = 'image_uploaded',
  IMAGE_UPLOAD_FAILED = 'image_upload_failed',
  OFFLINE_ASSETS_CACHING_INITIATED = 'offline_assets_caching_initiated',
  OFFLINE_ASSETS_CACHING_ASSETS_TOTAL_COUNT = 'offline_assets_caching_assets_total_count',
  OFFLINE_ASSETS_CACHING_FEEDERS_TOTAL_COUNT = 'offline_assets_caching_feeders_total_count',
  OFFLINE_ASSETS_CACHING_FEEDER_ID = 'offline_assets_caching_feeder_id',
  OFFLINE_ASSETS_CACHING_FAILED = 'offline_assets_caching_failed',
  OFFLINE_MODE_TOGGLED = 'offline_mode_toggled',
  OFFLINE_UPLOAD_FAILED_ADDING_DEFECT = 'offline_upload_failed_adding_defect',
  OFFLINE_UPLOAD_FAILED_INCOMPLETE = 'offline_upload_failed_incomplete',
  OFFLINE_UPLOAD_FAILED_LOCATION_MISSING = 'offline_upload_failed_location_missing',
  OFFLINE_UPLOAD_FAILED_UNSUPPORTED_BLOB = 'offline_upload_failed_unsupported_blob',
  OFFLINE_UPLOAD_SUCCEEDED = 'offline_upload_succeeded',
  PAGE_VIEW = 'page_view',
  REQUEST_RETRY = 'request_retry',
  LAYER_ON = 'layer_turned_on',
  LIGHTBOX_OPEN = 'open_lightbox',
  LOGIN_ERROR = 'login_error',
  RESET_FILTERS = 'reset_filters',
  SEARCH = 'search',
  STREET_VIEW = 'street_view',
  TABLE_EXPORT_COLUMN_SELECTED = 'table_export_-_column_selected',
  TABLE_CUSTOMIZED = 'table_customized',
  TABLE_CSV_EXPORTED = 'table_csv_exported',
  TABLE_PDF_EXPORTED = 'table_pdf_exported',
  TABLE_SORTED = 'table_sorted',
  TABLE_WITH_MAP_EXPORTED = 'table_with_map_exported',
  TIMELINE_EXPANDED = 'timeline_expanded',
  TIMELINE_FEATURE_NAVIGATION = 'timeline_feature_navigation',
  VIEW_IMAGE = 'view_image',
  VIEW_FILE = 'view_file',
}

/**
 * Event that is used to track using gtag.js of Google analytics. Read more at
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 * This content should be specified at call time.
 */
export declare interface BaseEvent {
  // TODO(b/287682217) event_category is deprecated in GA4.
  readonly event_category?: string;
  readonly event_label?: string;
  readonly value?: number;
}

declare interface CustomMap {
  dimension1?: string;
}

const CUSTOM_MAP: CustomMap = {
  dimension1: 'user_type',
};

/**
 * Pageview fields defined in Google Analytics:
 * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 */
export declare interface GtagPageview {
  readonly page_title?: string;
  readonly page_location?: string;
  readonly page_path?: string;
  readonly custom_map?: CustomMap;
  readonly user_type?: string;
  readonly user_id?: string;
}

/**
 * Service for sending Google Analytics events and pageviews using Gtags.
 * The Gtag object is provided in index.html.
 */
@Injectable()
export class AnalyticsService {
  propertyId: string;

  // Default with the assumption that a user is a Google user, and only
  // send analytics once we're sure that the user is an external user.
  isGoogleUser = true;

  constructor(
    private readonly configService: ConfigService,
    private readonly userPreferencesService: UserPreferencesService,
  ) {
    this.propertyId = this.configService.gaTrackerId;
    gtag('config', this.propertyId, {
      custom_map: CUSTOM_MAP,
    });
  }

  /**
   * Send an event to Google Analytics.
   */
  sendEvent(action: EventActionType, event: BaseEvent) {
    if (!this.isGoogleUser || this.configService.googleCorpAnalyticsEnabled) {
      const label = this.configService.googleCorpAnalyticsEnabled
        ? `${ConfigParams.GOOGLE_CORP_ANALYTICS_ENABLED}: ${event.event_label}`
        : event.event_label;
      gtag('event', action, {
        event_category: event.event_category,
        event_label: label,
        value: event.value,
        user_type: this.userPreferencesService.getSelectedUserTypeString(),
      });
    }
  }

  /**
   * Send a pageview to Google Analytics.
   */
  sendPageview(pageview: GtagPageview) {
    if (!this.isGoogleUser || this.configService.googleCorpAnalyticsEnabled) {
      gtag('event', EventActionType.PAGE_VIEW, {
        page_title: pageview.page_title,
      });
    }
  }

  setGoogleUser(isGoogleUser: boolean) {
    // Disabling GA4 analytics for the internal users.
    // https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out
    Object.defineProperty(window, `ga-disable-${this.propertyId}`, {
      value: isGoogleUser && !this.configService.googleCorpAnalyticsEnabled,
    });
    this.isGoogleUser = isGoogleUser;
  }
}
