import {Timestamp} from '@bufbuild/protobuf';

import {formatDate} from '@angular/common';

import {MONTH_YEAR_FORMAT} from '../constants/common';

/**
 * Converts a timestamp to a date string in the format YYYY/MM/DD.
 */
export function timestampToDateString(ts: Timestamp): string {
  return dateToDateString(ts.toDate());
}

/**
 * Converts a timestamp to a date string in the format YYYY/MM/DD.
 */
export function timestampToDateStringFormatted(ts: Timestamp, dateFormat: string): string {
  return dateToDateStringWithFormat(ts.toDate(), dateFormat);
}

/**
 * Converts a date to a date string in the format YYYY/MM/DD.
 */
export function dateToDateString(d: Date): string {
  // This is not i18n compliant.
  return formatDate(d, 'yyyy/MM/dd', 'en_US');
}

/**
 * Converts a date to a date string in the format MMM yyyy.
 */
export function dateToDateStringWithMonthLevelFormat(d: Date): string {
  return dateToDateStringWithFormat(d, MONTH_YEAR_FORMAT);
}

/**
 * Converts a date to a date string in the format YYYY/MM/DD.
 */
export function dateToDateStringWithFormat(d: Date, dateFormat: string): string {
  // This is not i18n compliant.
  return formatDate(d, dateFormat, 'en_US');
}

/**
 * Converts a date string to a date. The input string is expected to be in the
 * format YYYY/MM/DD.
 */
export function dateStringToDate(d: string): Date {
  // TODO(aegorov): Revisit date-to-string and reverse conversion logic and
  //  the code that uses this in general. Conversion of date to string should be
  //  unnecessary. Some code that may be used as example:
  //  https://source.corp.google.com/piper///depot/google3/googlex/refinery/viaduct/simulation/gridviz/frontend/pipes/local_date.ts
  //  https://angular.io/api/common/DatePipe
  //  https://source.corp.google.com/piper///depot/google3/googlex/refinery/viaduct/simulation/gridviz/frontend/util/datetime.ts
  return new Date(d.replace(/(\d{4})\.(\d{2})\.(\d{2})/, '$1-$2-$3'));
}

/**
 * Converts a timestamp to a date string in the format YYYY/MM/DD HH:MM.
 */
export function timestampToDateTimeString(ts: Timestamp): string {
  return dateToDateTimeString(ts.toDate());
}

/**
 * Converts a date to a date string in the format YYYY/MM/DD HH:MM.
 */
export function dateToDateTimeString(d: Date): string {
  // This is not i18n compliant.
  return formatDate(d, 'yyyy/MM/dd HH:mm', 'en_US');
}

/**
 * Determines whether two dates represent different point in time.
 * Defaults to true if either value is missing.
 */
export function isDifferentDate(firstDate: Date | null, secondDate: Date | null): boolean {
  if (firstDate && secondDate) {
    return firstDate.getTime() !== secondDate.getTime();
  }
  return true;
}

/**
 * Returns an array of dates in form YYYY/MM/DD. Each array entry represents
 * a single day.
 */
export function buildFormattedDateRange(startDate: Date, endDate: Date): string[] {
  const currentDate = new Date(startDate.valueOf());
  const dates = [];
  while (currentDate <= endDate) {
    dates.push(dateToDateString(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

/**
 * Convert a date to a relative time string, such as
 * "a minute ago", "in 2 hours", "yesterday", "3 months ago", etc.
 * using Intl.RelativeTimeFormat.
 *
 * Taken from https://www.builder.io/blog/relative-time and similar to
 * the date-fns library.
 */
export function getRelativeTimeString(date: Date | number, lang = navigator.language): string {
  // Allow dates or times to be passed.
  const timeMs = typeof date === 'number' ? date : date.getTime();

  // Get the amount of seconds between the given date and now.
  const deltaSeconds = Math.round((timeMs - Date.now()) / 1000);

  // Array representing one minute, hour, day, week, month, etc in seconds.
  const cutoffs = [60, 3600, 86400, 86400 * 7, 86400 * 30, 86400 * 365, Infinity];

  // Array equivalent to the above but in the string representation of the units.
  const units: Intl.RelativeTimeFormatUnit[] = [
    'second',
    'minute',
    'hour',
    'day',
    'week',
    'month',
    'year',
  ];

  // Grab the ideal cutoff unit.
  const unitIndex = cutoffs.findIndex((cutoff) => cutoff > Math.abs(deltaSeconds));

  // Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
  // is one day in seconds, so we can divide our seconds by this to get the # of days.
  const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1;

  // Let Intl.RelativeTimeFormat do its magic.
  const rtf = new Intl.RelativeTimeFormat(lang, {numeric: 'auto'});
  return rtf.format(Math.floor(deltaSeconds / divisor), units[unitIndex]);
}
