// TODO(b/177392058): Remove asset ID from frontend after Asset -> Feature
// migration.
/**
 * The ID of the Assets layer.
 */
export const ASSETS_LAYER_ID = 'assets';

/**
 * The ID of the Defects layer.
 */
export const DEFECTS_LAYER_ID = 'defects';

/**
 * The ID of the Sunroof layer.
 */
export const SUNROOF_LAYER_ID = 'SUNROOF';

/**
 * The ID of the Autotopology layer.
 */
export const AUTOTOP_LAYER_ID = 'autotop';

/**
 * The name of the circuit boundaries layer.
 */
export const CIRCUITS_LAYER_NAME = 'Circuit boundaries';

/**
 * The ID of the ICP layer.
 */
export const ICP_LAYER_ID = 'icp';

/**
 * The ID of the Solar Insights 2 layer.
 */
export const SOLAR_INSIGHTS_LAYER_ID = 'Solar Insights';

/**
 * The ID of the Street View recency layer id.
 */
export const STREETVIEW_RECENCY_LAYER_ID = 'Street View Recency';

/**
 * List of layers containing features that have images.
 */
export const LAYERS_CONTAINING_IMAGES = new Set<string>([
  ASSETS_LAYER_ID,
  AUTOTOP_LAYER_ID,
  DEFECTS_LAYER_ID,
]);
