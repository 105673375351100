import {PromiseClient} from '@connectrpc/connect';
import {User} from 'firebase/auth';
// import {noAwait} from 'google3/javascript/typescript/contrib/async';
import {Observable, ReplaySubject} from 'rxjs';

import {Injectable, NgZone} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';

import {UserService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/userservice_connect';

import {ROUTE} from '../constants/paths';
import {ConfigService} from '../services/config_service';
import {AnalyticsService, EventActionType, EventCategoryType} from './analytics_service';
import {ApiService} from './api_service';
// import {ApiService} from './api_service';
import {AuthService} from './auth_service';

// import {EventActionType, EventCategoryType} from '../../src/services/analytics_service';
// import {NetworkService} from './network_service';

/**
 * Paths that are available in offline mode.
 */
const OFFLINE_PATHS = [ROUTE.PHOTO_UPLOAD, ROUTE.PENDING_UPLOAD, ROUTE.OFFLINE];

/**
 * A CanActivate guard used to authenticate requests
 * against the configured GCIP auth providers.
 */
@Injectable()
export class AuthGuard {
  private readonly userClient: PromiseClient<typeof UserService>;
  private readonly authorized$ = new ReplaySubject<void>(1);

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly apiService: ApiService,
    private readonly authService: AuthService,
    // private readonly networkService: NetworkService,
    private readonly configService: ConfigService,
    private readonly ngZone: NgZone,
    private readonly router: Router,
  ) {
    this.userClient = this.apiService.createUserServiceBEClient();
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.configService.authOverrideEnabled) {
      return Promise.resolve(true);
    }
    // const offline = await firstValueFrom(this.networkService.getOffline$());
    const offline = false;
    const path = `/${route.url[0]?.path}`;
    if (path === ROUTE.LOGIN && !offline) {
      return Promise.resolve(true);
    }
    if (offline) {
      const hasPermission = this.canPathBeUsedOffline(path);
      // if (!hasPermission) {
      //   noAwait(this.router.navigateByUrl(ROUTE.PHOTO_UPLOAD));
      // }
      return Promise.resolve(hasPermission);
    }
    const url = state.url;
    return new Promise((resolve) => {
      this.authService.onAuthStateChanged((user: User | null) => {
        this.analyticsService.setGoogleUser(!!user && this.authService.isGoogleUser());
        if (!user) {
          // this.sendAuthFailureEvent('Error auth: Missing user');
          this.authService.setErrorMessage('Access denied.');
          this.routeLogin(url);
          resolve(false);
          return;
        }
        gtag('config', appConfigObject.gaTrackerId, {user_id: user.uid});
        this.checkPermissions().then((hasPermission: boolean) => {
          if (!hasPermission) {
            this.sendAuthFailureEvent(`Error auth: No permission: User email: ${user.email}`);
            this.authService.setErrorMessage('Access denied.');
            this.routeLogin(url);
            resolve(false);
            return;
          }
          resolve(true);
        });
      });
    });
  }

  getAuthorized$(): Observable<void> {
    return this.authorized$.asObservable();
  }

  /**
   * Performs a GridAware API query in order to check if the authenticated user
   * has API permissions.
   */
  checkPermissions(): Promise<boolean> {
    return this.apiService
      .withCallOptions((options) => {
        return this.userClient.checkAccess({}, options);
      })
      .then(
        () => {
          this.authorized$.next();
          return true;
        },
        (error: Error) => {
          console.error(error);
          return false;
        },
      );
  }

  routeLogin(url: string): void {
    this.authService.setUrl(url);
    this.ngZone.run(() => this.router.navigateByUrl(ROUTE.LOGIN));
  }

  private sendAuthFailureEvent(label: string): void {
    console.error(label);
    this.analyticsService.sendEvent(EventActionType.AUTH_FAILURE, {
      event_category: EventCategoryType.ERROR,
      event_label: label,
    });
  }

  private canPathBeUsedOffline(path: string): boolean {
    return OFFLINE_PATHS.some((allowedPath: ROUTE) => allowedPath === path);
  }
}
