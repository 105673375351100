import {Timestamp} from '@bufbuild/protobuf';
import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';

import {Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {User} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/user_pb';

import {Annotation, AnnotationShape, LabelInfo, Rectangle} from './annotations';

/**
 * Represents a file that's pending upload.
 */
export interface PendingFile {
  // Populated by FileReader when the file is selected.
  url: string;
  file: File;
  // Image ID for internal reference. Will be overwritten once the pending
  // file gets saved.
  readonly id: string;
}

/**
 * UploadFormTemplate is associated with an individual partner use case for the
 * upload flow. It specifies what form fields the user should fill out for that
 * use case, as well as information needed to "seed" the upload flow, such as
 * tags that should apply to all images.
 */
export interface UploadFormTemplate {
  name: string;
  initialTags: string[];
  sections: FormSection[];
  includeLocationDescription?: boolean;
}

/**
 * Each physical grouping of form fields with a header in a form.
 */
export interface FormSection {
  // Which step in the form stepper to show this section.
  formStep?: FormStep;
  fields: FormField[];
}

/**
 * Represents form data of specific type and configuration.
 */
export interface FormField {
  // The header name to show with the form field.
  header?: string;
  subheader?: string;
  propertyName: string;
  placeholder: string;
  type: FieldType;
  // Will be present if the type is SELECTION or CHECKBOX.
  options?: string[];
  required?: boolean;
  // Will be present if the type is PROPERTY_LOOKUP. Will look at all related
  // features for key-value pairs and display those related features.
  propertiesToLookup?: Property[];
  // Default value to use if no value is selected. Should only
  // be populated if required is false.
  default?: string;
}

/**
 * The fields with their respective values for pre filling the editing form.
 */
export declare interface UploadForm {
  // Properties set on the defect itself.
  tags: Set<string>;
  // An associated asset ID if one exists.
  externalId: string | undefined;
  // Additional properties.
  extraProperties?: Property[];
}

/**
 * Set of parameters that must be passed into an upload dialog.
 */
export interface UploadDialogData {
  featureId?: string;
  layerId?: string;
  edit?: boolean;
  assetId?: string;
  externalId?: string;
  preserveState?: boolean;
}

/**
 * Represents a property based on an observation.
 */
export interface ObservationProperty {
  key: string;
  value: string;
}

/**
 * The steps within a stepped form.
 */
export enum FormStep {
  INFORMATION = 'INFORMATION',
  ASSESSMENT = 'ASSESSMENT',
}

/**
 * The state of an upload.
 */
export enum UploadState {
  // Not started.
  PENDING = 'PENDING',
  // Still uploading.
  UPLOADING = 'UPLOADING',
  // Completed successfully.
  SUCCEEDED = 'SUCCEEDED',
  // Edit successfully completed.
  EDIT_SUCCEEDED = 'EDIT_SUCCEEDED',
  // Failed because there was an issue with the blob itself. This could be
  // because the file type is unsupported, size is too large, etc...
  FAILED_UNSUPPORTED_BLOB = 'FAILED_UNSUPPORTED_BLOB',
  // Failed because a location wasn't provided and one didn't exist on the
  // image metadata.
  FAILED_LOCATION_MISSING = 'FAILED_LOCATION_MISSING',
  // Failed incomplete is a catch-all failure. This could be because a service
  // was down or any other various error was thrown.
  FAILED_INCOMPLETE = 'FAILED_INCOMPLETE',
  // Defect removal successful.
  REMOVED = 'REMOVED',
  // Edit failed.
  EDIT_FAILED = 'EDIT_FAILED',
}

/**
 * The upload form with files, upload date, and location from browser.
 */
export declare interface PendingUploadGroup {
  uploadForm: UploadForm;
  files: File[];
  uploadedAt: Date;
  locationFromBrowser: LatLng | null;
  annotationsPerFile: Annotation[][];
  pendingUploadID?: number;
}

/**
 * A pending upload group which is written to and read from indexedDB. The
 * keyword declare must be used here so that minified object representations
 * aren't stored in indexedDB.
 */
export declare interface PendingUploadGroupForDb {
  uploadForm: UploadForm;
  files: File[];
  uploadedAt: Date;
  locationFromBrowser: string;
  extraProperties: OfflineProperty[];
  annotationsPerFile: AnnotationForDb[][];
}

/**
 * A pending upload Annotation, part of PendingUploadGroupForDb which is written
 * to and read from indexedDB.
 */
export declare interface AnnotationForDb {
  label: LabelInfo | null;
  comment: string;
  user?: User;
  updatedAt: Timestamp | undefined;
  createdAt: Timestamp | undefined;
  isMachineGenerated: boolean;
  machineModelVersion: string;
  machineConfidenceScore: number;
  sourceAnnotation: string;
  shapeType: AnnotationShape;
  shapeData: Rectangle;
}

/**
 * Protos cannot be properly serialized by IndexDB, so this serves as a
 * temporary storage for serializing.
 */
export declare interface OfflineProperty {
  key: string;
  value: string;
}

/**
 * Field types that are available.
 */
export enum FieldType {
  TEXT,
  TEXTAREA,
  BINARY,
  CHECKBOX,
  SELECTION,
  NUMBER,
  MAP,
  TAGS,
  PROPERTY_LOOKUP,
  ANNOTATION_SUMMARY,
  MATERIAL_SELECTION,
  MULTISELECT,
}

/**
 * Property names not tied to a specific form.
 */
export enum GlobalFormPropertyName {
  INSPECTION_STATUS = 'Inspection status',
  FORM_TYPE = 'Form type',
  LOCATION_DESCRIPTION = 'Location description',
  MAP = 'Map',
  TAGS = 'Tags',
  ANNOTATION_SUMMARY = 'Annotation Summary',
}
