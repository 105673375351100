import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';

import {BreadcrumbNavigationModule} from '../breadcrumb_navigation/breadcrumb_navigation_module';
import {PipesModule} from '../pipes/pipes_module';
import {FeederDetailsPage} from './feeder_details';

@NgModule({
  declarations: [FeederDetailsPage],
  imports: [
    BreadcrumbNavigationModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatTableModule,
    PipesModule,
  ],
  exports: [FeederDetailsPage],
})
export class FeederDetailsModule {}
