import {Timestamp} from '@bufbuild/protobuf';

import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';

import {FeatureMetadata} from '../typings/map';
import {getMetadataValue} from './metadata';

/**
 * The different sort directions that are available.
 */
export type SortDirection = 'asc' | 'desc' | '';

/**
 * The static sortable image fields that are allowed. Other sortable image
 * fields are dynamic as they exist on Metadata.
 */
export enum StaticSortableImageField {
  UPLOADED_AT = 'uploaded at',
  DESCRIPTION = 'description',
  USER = 'user',
  TAGS = 'tags',
}

/**
 * Sorts images from one or more groups using bearing if available, or recency.
 */
export function sortImageGroup(data: Image[]) {
  data = [...data];
  data.sort((imageA: Image, imageB: Image) => {
    const bearingA = imageA.location?.bearing ?? -1;
    const bearingB = imageB.location?.bearing ?? -1;
    if (bearingA === -1 || bearingB === -1) {
      return compareTimestamps(imageA.uploadedAt ?? null, imageB.uploadedAt ?? null, 'desc');
    }
    return bearingA - bearingB;
  });
  return data;
}

/**
 * Sort images by recency (upload time).
 */
export function sortImagesByUploadTime(data: Image[]): Image[] {
  data = [...data];
  data.sort((imageA: Image, imageB: Image) =>
    compareTimestamps(imageA.uploadedAt ?? null, imageB.uploadedAt ?? null, 'desc'),
  );
  return data;
}

/**
 * Sorts images from one or more groups using bearing if available, or capture time.
 */
export function sortImageGroupWithCaptureTimeFallback(data: Image[]) {
  data = [...data];
  data.sort((imageA: Image, imageB: Image) => {
    const bearingA = imageA.location?.bearing ?? -1;
    const bearingB = imageB.location?.bearing ?? -1;
    if (bearingA === -1 || bearingB === -1) {
      return compareTimestamps(imageA.capturedAt ?? null, imageB.capturedAt ?? null, 'desc');
    }
    return bearingA - bearingB;
  });
  return data;
}

/**
 * Sorts Images by a column name and direction.
 */
export function sortImages(columnName: string, direction: SortDirection, data: Image[]) {
  data = [...data];
  data.sort((imageA: Image, imageB: Image) => {
    if (columnName === StaticSortableImageField.UPLOADED_AT) {
      return compareTimestamps(imageA.uploadedAt ?? null, imageB.uploadedAt ?? null, direction);
    }
    if (columnName === StaticSortableImageField.USER) {
      const userNameA = imageA.user?.name;
      const userNameB = imageB.user?.name;

      return compareStrings(userNameA ?? null, userNameB ?? null, direction);
    }
    if (columnName === StaticSortableImageField.DESCRIPTION) {
      return compareStrings(imageA.description, imageB.description, direction);
    }
    if (columnName === StaticSortableImageField.TAGS) {
      const tagsA = imageA.tags.join(', ');
      const tagsB = imageB.tags.join(', ');
      return compareStrings(tagsA, tagsB, direction);
    }
    // Sort values from metadata.
    const metadataValueA = getMetadataValue(columnName, imageA.metadata);
    const metadataValueB = getMetadataValue(columnName, imageB.metadata);
    return compareStrings(metadataValueA, metadataValueB, direction);
  });
  return data;
}

/**
 * Sorts FeatureMetadata by layer ID then feature name.
 */
export function sortFeatureMetadata(a: FeatureMetadata, b: FeatureMetadata) {
  if (a.layerId !== b.layerId) {
    return a.layerId < b.layerId ? -1 : a.layerId > b.layerId ? 1 : 0;
  }
  const nameA = a.feature.name.toLowerCase();
  const nameB = b.feature.name.toLowerCase();
  return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
}

function compareTimestamps(
  a: Timestamp | null,
  b: Timestamp | null,
  direction: SortDirection,
): number {
  if (isEmpty(a) || isEmpty(b)) {
    return compareMissing(a, b);
  }
  const sortOrder = a!.toDate() < b!.toDate() ? -1 : a!.toDate() > b!.toDate() ? 1 : 0;
  return direction === 'asc' ? sortOrder : -1 * sortOrder;
}

function compareStrings(a: string | null, b: string | null, direction: SortDirection) {
  if (isEmpty(a) || isEmpty(b)) {
    return compareMissing(a, b);
  }
  a = a!.toLowerCase();
  b = b!.toLowerCase();
  const sortOrder = a < b ? -1 : a > b ? 1 : 0;
  return direction === 'asc' ? sortOrder : -1 * sortOrder;
}

/**
 * Sorts missing data at end.
 */
function compareMissing(a: unknown, b: unknown): number {
  if (a && b) {
    // If at least one parameter is not null, log and leave data unchanged.
    // Write tests for this.
    // TODO(reubenn): Add some type of debug-time logging.
    return 0;
  }
  return isEmpty(a) && isEmpty(b) ? 0 : isEmpty(a) ? 1 : -1;
}

function isEmpty(a: unknown) {
  return a === null || a === undefined || a === '';
}
