import {BehaviorSubject, Observable, Subject, of} from 'rxjs';
import {distinctUntilChanged, map, switchMap, takeUntil} from 'rxjs/operators';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';

import {SolarInsight} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/solar_insight_pb';

import {ICP_LAYER_ID} from '../constants/layer';
import {FEEDER_NAME} from '../constants/paths';
import {ConfigService} from '../services/config_service';
import {FeederMetrics, SolarInsightsService} from '../services/solar_insights_service';

interface UrlParams {
  feederName: string;
}

// Used to display feeder metrics in the table.
export interface TableDataObject {
  [FeederMetrics.PERCENT_RESIDENTIAL]?: number;
  [FeederMetrics.PERCENT_COMMERCIAL]?: number;
  [FeederMetrics.PERCENT_OTHER]?: number;
  [FeederMetrics.MAX_CONNECTED_CAPACITY]?: number;
  [FeederMetrics.AVG_MAX_KW_RESIDENTIAL]?: number;
  [FeederMetrics.AVG_MAX_KW_COMMERCIAL]?: number;
  [FeederMetrics.MAX_YEARLY_KWH_POTENTIAL_AC]?: number;
  [FeederMetrics.MAX_M2_PANEL_AREA]?: number;
  [FeederMetrics.TOTAL_M2_ROOF_AREA]?: number;
  [FeederMetrics.AVG_ROOF_MAX_SUNSHINE_HRS_PER_M2]?: number;
  [FeederMetrics.NE_ONLY_KW_CONNECTED_CAPACITY]?: number;
  [FeederMetrics.NE_ONLY_AVG_KW_RESIDENTIAL]?: number;
  [FeederMetrics.NE_ONLY_AVG_KW_COMMERCIAL]?: number;
  [FeederMetrics.NE_ONLY_YEARLY_KWH_POTENTIAL_AC]?: number;
  [FeederMetrics.CARBON_OFFSET_FACTOR_PER_MWH]?: number;
  // Used to display a divider between metrics with the value as the title.
  [FeederMetrics.DIVIDER]?: string;
}

/**
 * Component for rendering Feature Details Page. Reads a layer key and feature
 * from the URL and displays the details of the feature.
 */
@Component({
  templateUrl: 'feeder_details.ng.html',
  styleUrls: ['feeder_details.scss'],
})
export class FeederDetailsPage implements OnInit, OnDestroy {
  private readonly destroyed = new Subject<void>();
  protected readonly icpLayerId = ICP_LAYER_ID;
  // Used to display a selected Feeder Code
  feederCode = new BehaviorSubject<string>('');
  // Used to render the table columns
  protected readonly displayedColumns = ['keyValue'];

  // Show Solar2 Insights if flag is enabled and viewing Solar Insights layer.
  showSolar2Insights = false;
  tableData: TableDataObject[] = [];

  constructor(
    private readonly configService: ConfigService,
    private readonly route: ActivatedRoute,
    private readonly solarInsightsService: SolarInsightsService,
  ) {}

  ngOnInit() {
    this.setSolarInsights();
    this.route.paramMap
      .pipe(
        distinctUntilChanged(),
        map((paramMap: ParamMap) => ({
          feederName: paramMap.get(FEEDER_NAME) as string,
        })),
        switchMap((urlParams: UrlParams): Observable<string> => {
          const {feederName} = urlParams;
          return of(feederName);
        }),
        takeUntil(this.destroyed),
      )
      .subscribe((feederCode: string) => {
        const decodedFeederCode = decodeURIComponent(feederCode);

        this.feederCode.next(decodedFeederCode);
      });
    this.listenToFeederChange();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  protected getMetricLabel(value: unknown): string {
    return String(value);
  }

  private setSolarInsights() {
    this.showSolar2Insights = this.configService.solar2Enabled;
  }

  private listenToFeederChange() {
    this.solarInsightsService
      .getAllCachedFeeders()
      .pipe(
        switchMap(
          (feeders: SolarInsight[]): Observable<SolarInsight | undefined> =>
            this.feederCode.pipe(
              map((selectedFeederCode: string): SolarInsight | undefined =>
                feeders.find(
                  (feeder) => feeder.aggregationType.value?.feederCode === selectedFeederCode,
                ),
              ),
            ),
        ),
        takeUntil(this.destroyed),
      )
      .subscribe((feederMetrics: SolarInsight | undefined) => {
        this.updateTableData(feederMetrics);
      });
  }

  private updateTableData(feederMetrics: SolarInsight | undefined) {
    const percentResidential = {
      [FeederMetrics.PERCENT_RESIDENTIAL]: feederMetrics?.percentResidential || 0,
    };
    const percentCommercial = {
      [FeederMetrics.PERCENT_COMMERCIAL]: feederMetrics?.percentCommercial || 0,
    };
    const percentOther = {
      [FeederMetrics.PERCENT_OTHER]: feederMetrics?.percentOther || 0,
    };
    const maxConnectedCapacity = {
      [FeederMetrics.MAX_CONNECTED_CAPACITY]: feederMetrics?.maxKwConnectedCapacity || 0,
    };
    const avgMaxKwResidential = {
      [FeederMetrics.AVG_MAX_KW_RESIDENTIAL]: feederMetrics?.avgMaxKwResidential || 0,
    };
    const avgMaxKwCommercial = {
      [FeederMetrics.AVG_MAX_KW_COMMERCIAL]: feederMetrics?.avgMaxKwCommercial || 0,
    };
    const maxYearlyKwhPotentialAc = {
      [FeederMetrics.MAX_YEARLY_KWH_POTENTIAL_AC]: feederMetrics?.maxYearlyKwhPotentialAc || 0,
    };
    const maxM2PanelArea = {
      [FeederMetrics.MAX_M2_PANEL_AREA]: feederMetrics?.maxM2PanelArea || 0,
    };
    const totalM2RoofArea = {
      [FeederMetrics.TOTAL_M2_ROOF_AREA]: feederMetrics?.totalM2RoofArea || 0,
    };
    const avgRoofMaxSunshineHrsPerM2 = {
      [FeederMetrics.AVG_ROOF_MAX_SUNSHINE_HRS_PER_M2]:
        feederMetrics?.avgRoofMaxSunshineHrsPerM2 || 0,
    };
    const neOnlyKwConnectedCapacity = {
      [FeederMetrics.NE_ONLY_KW_CONNECTED_CAPACITY]: feederMetrics?.neOnlyKwConnectedCapacity || 0,
    };
    const neOnlyAvgKwResidential = {
      [FeederMetrics.NE_ONLY_AVG_KW_RESIDENTIAL]: feederMetrics?.neOnlyAvgKwResidential || 0,
    };
    const neOnlyAvgKwCommercial = {
      [FeederMetrics.NE_ONLY_AVG_KW_COMMERCIAL]: feederMetrics?.neOnlyAvgKwCommercial || 0,
    };
    const neOnlyYearlyKwhPotentialAc = {
      [FeederMetrics.NE_ONLY_YEARLY_KWH_POTENTIAL_AC]:
        feederMetrics?.neOnlyYearlyKwhPotentialAc || 0,
    };
    const carbonOffsetFactorPerMwh = {
      [FeederMetrics.CARBON_OFFSET_FACTOR_PER_MWH]: feederMetrics?.carbonOffsetFactorPerMwh || 0,
    };

    this.tableData = [
      percentResidential,
      percentCommercial,
      percentOther,
      totalM2RoofArea,
      avgRoofMaxSunshineHrsPerM2,
      {[FeederMetrics.DIVIDER]: 'Maximum Rooftop Usage'},
      maxConnectedCapacity,
      avgMaxKwResidential,
      avgMaxKwCommercial,
      maxYearlyKwhPotentialAc,
      maxM2PanelArea,
      {[FeederMetrics.DIVIDER]: 'NorthEast Facing Rooftop Usage'},
      neOnlyKwConnectedCapacity,
      neOnlyAvgKwResidential,
      neOnlyAvgKwCommercial,
      neOnlyYearlyKwhPotentialAc,
      {[FeederMetrics.DIVIDER]: 'Other Metrics'},
      carbonOffsetFactorPerMwh,
    ];
  }
}
