import {NgModule} from '@angular/core';
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  Router,
  RouterModule,
  Routes,
  TitleStrategy,
  mapToCanActivate,
} from '@angular/router';

import {AuthGuard} from '../../src/services/auth_guard';
import {FEATURE_PARAM_KEY, FEEDER_NAME, IMAGE_PARAM_KEY, LAYER_PARAM_KEY} from '../constants/paths';
import {CanDeactivateGuard} from '../core/navigation/can_deactivate_guard';
import {FeatureDetails} from '../feature_details/feature_details';
import {FeederDetailsPage} from '../feeder_details/feeder_details';
import {GalleryPage} from '../gallery/gallery';
import {GalleryInfo} from '../gallery/gallery_info/gallery_info';
import {LayerConfigContainerPage} from '../layer_config/layer_config_container';
import {AuthLoadingComponent} from '../login/auth_loading';
import {LoginComponent} from '../login/login_component';
import {MapRouteComponent} from '../map/map_route_component';
import {MapLayersComponent} from '../map_layers/map_layers_component';
import {PageErrorsComponent} from '../page_errors/page_errors_component';
import {PendingUploadPage} from '../pending_upload/pending_upload';
import {AnalyticsService} from '../services/analytics_service';
import {SolarTablePage} from '../solar_table/solar_table';
import {SunroofDetailsPage} from '../sunroof_details/sunroof_details';
import {Table} from '../table/table';
import {UploadPage} from '../upload/upload';
import {AppTitleStrategy} from './app_title_strategy';

const routes: Routes = [
  // TODO(b/325657435): Implement login flow.
  {
    path: 'login',
    component: LoginComponent,
    canActivate: mapToCanActivate([AuthGuard]),
    children: [
      {
        path: 'loading',
        component: AuthLoadingComponent,
      },
    ],
    title: 'Login',
  },
  {
    path: 'settings',
    component: LayerConfigContainerPage,
    canActivate: mapToCanActivate([AuthGuard]),
    title: 'Settings',
  },
  {
    path: 'map',
    canActivate: mapToCanActivate([AuthGuard]),
    component: MapRouteComponent,
    children: [
      {
        path: '',
        component: MapLayersComponent,
      },
      {
        path: 'sunroof',
        component: SunroofDetailsPage,
      },
      {
        path: `solar/:${FEEDER_NAME}`,
        component: FeederDetailsPage,
      },
      {
        path: `:${LAYER_PARAM_KEY}/:${FEATURE_PARAM_KEY}`,
        component: FeatureDetails,
      },
      {
        path: `:${LAYER_PARAM_KEY}/:${FEATURE_PARAM_KEY}/:${IMAGE_PARAM_KEY}`,
        component: FeatureDetails,
      },
    ],
    title: 'Map',
  },
  {path: '', redirectTo: '/map', pathMatch: 'full'},
  {
    path: 'table',
    component: Table,
    canActivate: mapToCanActivate([AuthGuard]),
    title: 'Table',
    children: [
      {
        path: '',
        component: MapLayersComponent,
      },
      {
        path: `:${LAYER_PARAM_KEY}`,
        component: MapLayersComponent,
      },
      {
        path: `:${LAYER_PARAM_KEY}/:${FEATURE_PARAM_KEY}`,
        component: FeatureDetails,
      },
      {
        path: `:${LAYER_PARAM_KEY}/:${FEATURE_PARAM_KEY}/:${IMAGE_PARAM_KEY}`,
        component: FeatureDetails,
      },
    ],
  },
  {
    path: 'solar/details/table',
    component: SolarTablePage,
    canActivate: mapToCanActivate([AuthGuard]),
    // To make sure that canActivate guards are triggered
    // for every navigation.
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: `:${FEEDER_NAME}`,
        component: FeederDetailsPage,
      },
    ],
    title: 'Feeder Details',
  },
  {
    path: 'lightbox',
    component: GalleryPage,
    canActivate: mapToCanActivate([AuthGuard]),
    children: [
      {
        path: '',
        component: GalleryInfo,
      },
    ],
    title: 'Gallery',
  },
  {
    path: 'upload',
    component: UploadPage,
    canActivate: mapToCanActivate([AuthGuard]),
    canDeactivate: [CanDeactivateGuard],
    title: 'Upload',
  },
  {
    path: 'upload/pending',
    component: PendingUploadPage,
    canActivate: mapToCanActivate([AuthGuard]),
    title: 'Pending Upload',
  },
  {
    path: 'error/:code',
    component: PageErrorsComponent,
    canActivate: mapToCanActivate([AuthGuard]),
    title: 'Error',
  },
  {path: '**', redirectTo: 'error/404'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
  providers: [{provide: TitleStrategy, useClass: AppTitleStrategy}],
})
export class AppRoutingModule {
  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const pageTitle = this.activatedRoute?.root?.firstChild?.snapshot?.title;
        this.analyticsService.sendPageview({
          page_title: pageTitle,
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }
}
