import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatSelectChange} from '@angular/material/select';

import {PropertyKey} from '../../constants/properties';
import {FormField, ObservationProperty} from '../../typings/upload';
import {VTS_FORM} from '../upload_form/forms';

interface ObservationInfo {
  priority?: string;
  networkLevel?: string;
}

@Component({
  selector: 'observation-input',
  templateUrl: './observation_input.html',
  styleUrl: './observation_input.scss',
})
export class ObservationInput {
  @Input() field!: FormField;
  @Output() completeStatusChanged = new EventEmitter<boolean>();
  @Output() propertiesSet = new EventEmitter<ObservationProperty>();

  priorityLevels = VTS_FORM.priorityLevels;
  netWorkLevels = VTS_FORM.networkLevels;

  observations = new Map<string, ObservationInfo>();

  onSelectedOptionsChanged(options: string[]) {
    for (const option of options) {
      if (!this.observations.has(option)) {
        this.observations.set(option, {});
      }
    }
    for (const observationCode of this.observations.keys()) {
      if (!options.includes(observationCode)) {
        this.observations.delete(observationCode);
      }
    }

    this.updateCompleteStatus();
  }

  isFormComplete(): boolean {
    for (const [, observationInfo] of this.observations.entries()) {
      if (observationInfo.networkLevel === undefined || observationInfo.priority === undefined) {
        return false;
      }
    }
    return true;
  }

  updateCompleteStatus() {
    if (this.isFormComplete()) {
      this.sendProperties();
    }

    this.completeStatusChanged.next(this.isFormComplete());
  }

  sendProperties() {
    let observationCodeStr = '';
    let priorityLevelsStr = '';
    let networkLevelsStr = '';
    for (const [observation, observationInfo] of this.observations) {
      observationCodeStr += observation + ', ';
      priorityLevelsStr += observationInfo.priority + ', ';
      networkLevelsStr += observationInfo.networkLevel + ', ';
    }

    // Trim trailing commas.
    observationCodeStr = observationCodeStr.slice(0, -2);
    priorityLevelsStr = priorityLevelsStr.slice(0, -2);
    networkLevelsStr = networkLevelsStr.slice(0, -2);

    const observationCodesProp: ObservationProperty = {
      key: PropertyKey.OBSERVATION_CODES,
      value: observationCodeStr,
    };
    const priorityLevelsProp: ObservationProperty = {
      key: PropertyKey.OBSERVATION_PRIORITY_LEVELS,
      value: priorityLevelsStr,
    };
    const networkLevelsProp: ObservationProperty = {
      key: PropertyKey.OBSERVATION_NETWORK_LEVELS,
      value: networkLevelsStr,
    };

    this.propertiesSet.next(observationCodesProp);
    this.propertiesSet.next(priorityLevelsProp);
    this.propertiesSet.next(networkLevelsProp);
  }

  onSubSelectionChanged(event: MatSelectChange, observation: string, subSelection: string) {
    const observationInfo = this.observations.get(observation)!;
    if (subSelection === 'Priority Level') {
      observationInfo.priority = event.value;
    }
    if (subSelection === 'Network Level') {
      observationInfo.networkLevel = event.value;
    }
    this.observations.set(observation, observationInfo);

    this.updateCompleteStatus();
  }
}
