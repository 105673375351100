import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatStepperModule} from '@angular/material/stepper';
import {RouterModule} from '@angular/router';

import {AnnotationSummaryModule} from '../../annotation_summary/annotation_summary_module';
import {AssetConfirmationModule} from '../../asset_confirmation/asset_confirmation_module';
import {MultiselectAutocompleteModule} from '../../multiselect_autocomplete/multiselect_autocomplete_module';
import {OfflineSearchModule} from '../../search/offline_search/offline_search_module';
import {TagsModule} from '../../tags/tags_module';
import {FeatureSelectionMapModule} from '../feature_selection_map/feature_selection_map_module';
import {ObservationInputModule} from '../observation_input/observation_input_module';
import {ImageBox} from './image_box';
import {OldUploadForm} from './old_upload_from';
import {UploadForm} from './upload_form';

@NgModule({
  declarations: [ImageBox, UploadForm, OldUploadForm],
  imports: [
    AnnotationSummaryModule,
    AssetConfirmationModule,
    CommonModule,
    FeatureSelectionMapModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatStepperModule,
    ObservationInputModule,
    MultiselectAutocompleteModule,
    //MaterialSelectionModule,
    OfflineSearchModule,
    ReactiveFormsModule,
    RouterModule,
    TagsModule,
  ],
  exports: [ImageBox, UploadForm, OldUploadForm],
})
export class UploadFormModule {}
